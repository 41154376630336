import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenericObjectView from "./GenericObjectView";
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PetRegistrationDto from "../Dtos/PetRegistrationDto";
import { Typography, Box, CircularProgress, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import LoadingSpinner from "./LoadingSpinner";
import EditIcon from "@mui/icons-material/Edit";

const RabbitView = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [rabbitData, setRabbitData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadRabbitData = useCallback(async () => {
    setLoading(true);
    try {
      const rabbit = await DjurliAPI.getRabbit(id, ApplicationState.getToken());
      const registration = await DjurliAPI.getPetRegistrationByPetId(
        id,
        ApplicationState.getToken()
      );

      if (rabbit.motherId) {
        rabbit.mother = await DjurliAPI.getRabbit(
          rabbit.motherId,
          ApplicationState.getToken()
        );
      }
      if (rabbit.fatherId) {
        rabbit.father = await DjurliAPI.getRabbit(
          rabbit.fatherId,
          ApplicationState.getToken()
        );
      }
      rabbit.offsprings = await DjurliAPI.getOffspringsForPet(
        id,
        ApplicationState.getToken()
      );
      try {
        rabbit.familyTree = await DjurliAPI.getFamilyTreeForPet(
          id,
          ApplicationState.getToken()
        );
      } catch (error) {
        console.error("Kunde inte hämta släktträd:", error);
        rabbit.familyTree = { html: "Kunde inte ladda släktträd." };
      }
      if (rabbit.breederId) {
        rabbit.breeder = await DjurliAPI.getBreeder(
          rabbit.breederId,
          ApplicationState.getToken()
        );
      }
      if (rabbit.ownerUserId) {
        rabbit.owner = await DjurliAPI.getUser(
          rabbit.ownerUserId,
          ApplicationState.getToken()
        );
      }

      rabbit.registration = registration;
      setRabbitData(rabbit);
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadRabbitData();
  }, [loadRabbitData]);

  const deleteRabbit = async (id, token) => {
    await DjurliAPI.deleteRabbit(id, token);
    history("/rabbits");
  };

  const getTitleString = (rabbit) => {
    return rabbit ? rabbit.name : "Okänd kanin";
  };

  const getRegistrationStatus = (registration) => {
    if (!registration) return "Ej registrerad";
    if (registration.approved) return "REGISTRERAD";
    return "REGISTRERING PÅGÅR";
  };
  
  const getGroupedData = (rabbit) => {
    if (!rabbit) return [];
    return [
      {
        title: "Grundläggande information",
        data: [
          { label: "Namn", value: rabbit.name },
          { label: "Kön", value: rabbit.sex === "F" ? "Hona" : rabbit.sex === "M" ? "Hane" : "?" },
          { label: "Födelsedatum", value: rabbit.dateOfBirth ? new Date(rabbit.dateOfBirth).toLocaleDateString() : undefined },
          { label: "Prefix", value: rabbit.prefix },
          { label: "RegNr", value: rabbit.identificationNumber },
          { label: "Registreringsstatus", value: getRegistrationStatus(rabbit.registration) },
        ]
      },
      {
        title: "Utseende",
        data: [
          { label: "Färg", value: rabbit.color },
          { label: "Teckning", value: rabbit.markings },
          { label: "Päls", value: rabbit.furType },
          { label: "Öron", value: rabbit.earType },
          { label: "Speciella kännetecken", value: rabbit.distinctiveFeatures },
        ]
      },
      {
        title: "Föräldrar",
        data: [
          { label: "Far", value: rabbit.father ? rabbit.father.name : undefined },
          { label: "Mor", value: rabbit.mother ? rabbit.mother.name : undefined },
        ]
      },
      {
        title: "Uppfödning och Ägande",
        data: [
          { label: "Uppfödare", value: rabbit.breeder ? rabbit.breeder.prefix : rabbit.breederText || undefined },
          { label: "Ägare", value: rabbit.owner ? `${rabbit.owner.firstName} ${rabbit.owner.lastName}` : rabbit.ownerText || undefined },
        ]
      },
      {
        title: "Övrig information",
        data: [
          { label: "Dödsdatum", value: rabbit.dateOfDeath ? new Date(rabbit.dateOfDeath).toLocaleDateString() : undefined },
          { label: "Dödsorsak", value: rabbit.causeOfDeath },
          { label: "Kommentar", value: rabbit.comment },
        ]
      }
    ];
  };

  const handleEditAction = () => {
    if (rabbitData) {
      ApplicationState.setRabbitToEdit(rabbitData);
      history("/rabbitCrud");
    } else {
      console.error("Försökte redigera en odefinierad kanin");
    }
  };

  const getAdditionalContentSections = useCallback(() => {
    if (!rabbitData) return [];
  
    const groupOffspringsByGeneration = (offsprings) => {
      return offsprings.reduce((acc, offspring) => {
        if (!acc[offspring.generation]) {
          acc[offspring.generation] = [];
        }
        acc[offspring.generation].push(offspring);
        return acc;
      }, {});
    };
  
    return [
      {
        title: "Släktträd",
        content: rabbitData.familyTree && rabbitData.familyTree.html ? (
          <div dangerouslySetInnerHTML={{ __html: rabbitData.familyTree.html }} />
        ) : (
          <Typography>Släktträd är inte tillgängligt.</Typography>
        )
      },
      {
        title: "Avkommor",
        content: rabbitData.offsprings && rabbitData.offsprings.length > 0 ? (
          Object.entries(groupOffspringsByGeneration(rabbitData.offsprings)).map(([generation, offsprings]) => (
            <Box key={generation}>
              <Typography variant="subtitle1" sx={{pt: 2}}>Generation {generation}</Typography>
              {offsprings.map((offspring, index) => (
                <Box sx={{pl: 2}} key={index}>
                  <Link 
                    to={`/rabbit/${offspring.id}`}
                    style={{ 
                      color: '#D6409B', 
                      textDecoration: 'none', 
                      '&:hover': { 
                        textDecoration: 'underline' 
                      } 
                    }}
                  >
                    {offspring.name}
                  </Link>
                </Box>
              ))}
            </Box>
          ))
        ) : (
          <Typography>Inga avkommor hittades.</Typography>
        )
      }
    ];
  }, [rabbitData]);

  const additionalMenuItems = [
    {
      label: "Logg",
      icon: AssignmentIcon,
      onClick: () => {
        history("/rabbitLogs/" + rabbitData.id);
      },
      condition: () => ApplicationState.isAdmin(),
    },
    {
      label: "Ladda ner PDF",
      icon: PictureAsPdfIcon,
      onClick: () => handleDownload(rabbitData.id),
    },
    {
      label: "Registrera med RegNr",
      icon: AddCircleOutlineIcon,
      onClick: async () => {
        try {
          let dto = new PetRegistrationDto(
            DjurliAPI.generateGuid(),
            rabbitData.id,
            undefined,
            undefined,
            true,
            "Rabbit"
          );
          await DjurliAPI.createPetRegistration(
            dto,
            ApplicationState.getToken()
          );
          await loadRabbitData();
        } catch (error) {
          history("/error", { state: { errorMessage: error.message } });
        }
      },
      condition: () => !rabbitData.registration,
    },
  ];

  if (loading) {
    return (
      <LoadingSpinner />
    );
  }

  if (!rabbitData) {
    return <Typography>Ingen kanindata tillgänglig.</Typography>;
  }

  const handleDownload = async (id) => {
    if (!id) {
      alert('Inget ID tillhandahållet');
      return;
    }
  
    try {
      const blob = await DjurliAPI.getRabbitPdf(id, ApplicationState.getToken());
  
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `filled_form_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
  
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    }
  };
  
  const additionalSections = getAdditionalContentSections();

  return (
    <GenericObjectView
      deleteRedirectPath="rabbits"
      objectTypeText="kanin"
      object={rabbitData}
      deleteObject={deleteRabbit}
      getTitleString={getTitleString}
      getGroupedData={getGroupedData}
      additionalContentSections={additionalSections}
      apiToken={ApplicationState.getToken()}
      onMainAction={handleEditAction}
      mainActionIcon={<EditIcon />}
      mainActionText="Redigera"
      additionalMenuItems={additionalMenuItems}
    />
  );
};

export default RabbitView;