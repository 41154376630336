import React from 'react';
import { TextField } from '@mui/material';

const CustomTextField = ({ 
  id, 
  type = 'text', 
  label, 
  value, 
  error, 
  helperText, 
  onChange,
  onBlur, 
  maxWidth = 600,
  multiline = false,
  ...props 
}) => {
  return (
    <TextField
      id={id}
      type={type}
      InputLabelProps={{
        shrink: true,// Detta säkerställer att label inte hamnar i vägen för värdet när det inte är fokus på komponenten
      }}
      label={label}
      variant="outlined"
      fullWidth
      sx={{
        margin: "8px 0",
        backgroundColor: "white",
        maxWidth: maxWidth,
      }}
      value={value}
      error={Boolean(error)}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      multiline={multiline}
      rows={multiline ? 4 : undefined}
      {...props}
    />
  );
};

export default CustomTextField;