import React from "react";
import { Box, Grid, CircularProgress, Typography } from "@mui/material";

export function LoadingIndicator() {
  return (
    <Box p={2}>
      <Grid container justifyContent="center" alignItems="center" style={{ paddingBottom: "8px" }}>
        <CircularProgress />
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="body2">Laddar...</Typography>
      </Grid>
    </Box>
  );
}

export function ErrorMessage({ error }) {
  return (
    <Box p={2}>
      <Typography color="error" variant="body1">
        {error}
      </Typography>
    </Box>
  );
}

export function NoItemsMessage({ title }) {
  return (
    <Box p={2}>
      <Typography variant="body1">
        Inga {title.toLowerCase()} matchar sökkriteriet
      </Typography>
    </Box>
  );
}