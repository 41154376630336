export default class MembershipDto {
    constructor(id, organizationId, startDate, endDate, userId, approved, approvedAt, price, familyMembership) {
      this.id = id;
      this.organizationId = organizationId;
      this.startDate = startDate;
      this.endDate = endDate;
      this.userId = userId;
      this.approved = approved;
      this.approvedAt = approvedAt;
      this.price = price;
      this.familyMembership = familyMembership;
    }
  }  