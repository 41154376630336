import React, { useEffect, useState, useCallback } from "react";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import logo from "../logo_text_right.svg";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppsIcon from "@mui/icons-material/Apps";
import HomeIcon from "@mui/icons-material/Home";
import Hidden from "@mui/material/Hidden";
import LoginView from "./LoginView";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ApplicationState from "../ApplicationState";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { styled } from "@mui/material/styles";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RatsView from "./RatsView";
import RatCrudView from "./RatCrudView";
import RatView from "./RatView";
import { ListItemButton } from "@mui/material";
import PetsIcon from '@mui/icons-material/Pets';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BreedersView from "./BreedersView";
import LittersView from "./LittersView";
import UsersView from "./UsersView";
import AdminToDoView from "./AdminToDoView";
import UserCrudView from "./UserCrudView";
import UserView from "./UserView";
import BreederView from "./BreederView";
import BreederCrudView from "./BreederCrudView";
import LitterCrudView from "./LitterCrudView";
import LitterView from "./LitterView";
import TestMatingView from "./TestMatingView";
import MembershipView from "./MembershipView";
import MembershipCrudView from "./MembershipCrudView";
import MembershipsView from "./MembershipsView";
import PetRegistrationsView from "./PetRegistrationsView";
import PetRegistrationView from "./PetRegistrationView";
import ChecklistIcon from '@mui/icons-material/Checklist';
import RatLogView from "./RatLogView";
import UserLogView from "./UserLogView";
import ErrorPage from "./ErrorPage";
import ErrorBoundary from "./ErrorBoundary";
import FAQView from "./FaqView";
import BreederLogView from "./BreederLogView";
import LitterLogView from "./LitterLogView";
import PetRegistrationLogView from "./PetRegistrationLogView";
import LitterBabyCrudView from "./LitterBabyCrudView";
import LitterBabyView from "./LitterBabyView";
import AccessDeniedView from "./AccessDeniedView";
import UserAvatar from './UserAvatar';
import MyPageView from "./MyPageView";
import ScienceIcon from '@mui/icons-material/Science';
import HomeView from "./HomeView";
import RabbitsView from "./RabbitsView";
import RabbitCrudView from "./RabbitCrudView";
import RabbitView from "./RabbitView";
import RabbitLogView from "./RabbitLogView";
import DjurliAPI from "../DjurliAPI";


const MenuList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    //backgroundColor: "white",
    // backgroundColor: "red !important",
    //backgroundAttachment: "fixed",
    //backgroundRepeat: "no-repeat",
    //backgroundImage: "linear-gradient(135deg, #282428 0%, #111 100%) ",
    color: "#333",
    textDecoration: "bold",
    '&, & .MuiListItemIcon-root': {
      color: '#111',  
    },
    color: "#111",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
});

const drawerWidth = 240;

export default function TopBar(props) {
  const [, setHasLoggedIn] = React.useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("Home");

  const { window2 } = props;
  const theme = useTheme();
  const [showMenuText, setShowMenuText] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const container =
    window2 !== undefined ? () => window().document.body : undefined;

  function doLogin() {
    setHasLoggedIn(true);
  }

  function logout() {
    ApplicationState.logout();
    window.location.href = "/";
    setHasLoggedIn(false);
    handleCloseUserMenu();
  }

  function handleCloseUserMenu() {
    setAnchorEl(null);
  }

  const drawer = (anchor) => (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      style={{ backgroundColor: "#111", color: "#fff", width: "100%", height: "100%" }}
    >
      <Grid item>
        <Box>
          <MenuList style={{ padding: "0px" }}>
            {ApplicationState.viewMenuItem() && (
              <Box>
                {/* <ListItemButton
                  component={NavLink}
                  to="/home"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Home");
                  }}
                  selected={selectedMenuItem === "Home"}
                  style={{ backgroundColor: "#111", paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <img
                          src={logo}
                          style={{
                            height: "32px",
                            width: "100px",
                            alignContent: "",
                          }}
                        />
                    
                  
                </ListItemButton>

                <Divider style={{ borderColor: "#f6f6f6" }} /> */}

                <ListItemButton
                  component={NavLink}
                  to="/home"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Home");
                  }}
                  selected={selectedMenuItem === "Home"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <HomeIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Hem" style={{color: "#fff"}} />}
                </ListItemButton>

                <ListItemButton
                  component={NavLink}
                  to={ApplicationState.getPetRoute()}
                  onClick={async () => {
                    setMobileOpen(false);
                    setSelectedMenuItem(ApplicationState.getPetMenuTitle());
                  }}
                  selected={selectedMenuItem === ApplicationState.getPetMenuTitle()}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <PetsIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary={ApplicationState.getPetMenuTitle()} style={{color: "#fff"}} />}
                </ListItemButton>

                <ListItem
                  component={NavLink}
                  to="/litters"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Litters");
                  }}
                  selected={selectedMenuItem === "Litters"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <EmojiNatureIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Kullar" style={{color: "#fff"}}/>}
                </ListItem>

                <ListItemButton
                  component={NavLink}
                  to="/testmating"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Testmating");
                  }}
                  selected={selectedMenuItem === "Testmating"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <ScienceIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Provparning" style={{color: "#fff"}} />}
                </ListItemButton>

                <ListItem
                  component={NavLink}
                  to="/breeders"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Breeders");
                  }}
                  selected={selectedMenuItem === "Breeders"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <NaturePeopleIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Uppfödare" style={{color: "#fff"}}/>}
                </ListItem>

                {/* <Divider style={{ borderColor: "#f6f6f6" }} /> */}

              </Box>
            )}

            {ApplicationState.isAdmin() && (
              <Box>
                <ListItem
                  component={NavLink}
                  to="/users"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Users");
                  }}
                  selected={selectedMenuItem === "Users"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <PeopleAltIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Användare" style={{color: "#fff"}}/>}
                </ListItem>

                <ListItemButton
                  component={NavLink}
                  to="/petRegistrations"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Registrations");
                  }}
                  selected={selectedMenuItem === "Registrations"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <ChecklistIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Registreringar" style={{color: "#fff"}} />}
                </ListItemButton>

                <ListItemButton
                  component={NavLink}
                  to="/admin"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Admin");
                  }}
                  selected={selectedMenuItem === "Admin"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <ListAltIcon style={{ color: "#EB71A9" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Att göra" style={{color: "#fff"}} />}
                </ListItemButton>


              </Box>
            )}
          </MenuList>
        </Box>
        {/* <Divider style={{ borderColor: "#f6f6f6" }} /> */}
        <Box p={2}></Box>
      </Grid>
      {/* <Grid item style={{ maxWidth: "100%" }}>
          <Grid item style={{ maxWidth: "100%" }}>
            <UserAvatar showName={true} />
          </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "12px",
            // backgroundColor: "#f6f6f6",
          }}
        >
        </Grid>
        {ApplicationState.isAdmin() && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{
              justifyContent: "center",
              paddingTop: "0px",
              // backgroundColor: "#f6f6f6",
            }}
          >
            
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Admin</Typography>
              </Grid>
              </Grid>
            
        )}
        {!ApplicationState.isAdmin() && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{
              justifyContent: "center",
              paddingTop: "0px",
              // backgroundColor: "#f6f6f6",
            }}
          >
            
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Användare</Typography>
              </Grid>
              </Grid>
            
        )}

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "8px",
            // backgroundColor: "#f6f6f6",
          }}
        >
          <Link
            component="button"
            onClick={logout}
            style={{ color: "#fff", textDecoration: "underline" }}
            underline="hover"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2">Logga ut</Typography>
            </Grid>
          </Link>
        </Grid>
      </Grid> */}
    </Grid>
  );

  if (!ApplicationState.hasState() || !ApplicationState.getUser()) {
    return (
      <>
        <Router>
          <ErrorBoundary>
        <Routes>
          {/* <Switch> */}
          <Route
            path="/login"
            element={<LoginView doLoginFunction={doLogin} />}
          ></Route>
          <Route
            path="/faq"
            element={<FAQView />}
          ></Route>
          <Route path="/error" element={<ErrorPage />}></Route>
          <Route
            path="*"
            element={<LoginView doLoginFunction={doLogin} />}
          ></Route>
        </Routes>
        {/* </Switch> */}
        </ErrorBoundary>
        </Router>
      </>
    );
  }

  return (
    <>
      <Router>
      <ErrorBoundary>
        <div style={{ display: "flex" }}>
          {["☰"].map((anchor) => (
            <React.Fragment key={anchor}>
              {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}
              <AppBar
                position="fixed"
                // className={classes.appBar}
                sx={{
                  backgroundColor: "#4A0E4E",
                  color: "#000",
                  //width: {xs: "", sm: `calc(100% - ${drawerWidth}px)`},
                  // [theme.breakpoints.up("sm")]: {
                  //   width: `calc(100% - ${drawerWidth}px)`,
                  //   marginLeft: drawerWidth,
                  // },
                }}
              >
                <Toolbar>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <ListItem
                        onClick={handleDrawerToggle}
                        sx={{
                          paddingBottom: "20px",
                          paddingTop: "20px",
                        }}
                        size="large"
                        variant="outlined"
                      >
                        <MenuIcon style={{ color: "#fff" }} />
                      </ListItem>
                    </Grid>
                    <Grid item>
                      <NavLink
                        to="/home"
                        onClick={() => {
                          setMobileOpen(false);
                          setSelectedMenuItem("Home");
                        }}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <img
                          src={logo}
                          style={{
                            height: "50px",
                            width: "155px",
                            margin: "8px 8px 8px 4px",
                            top: "57px",
                            left: "58px",
                            alignContent: "",
                          }}
                        />
                        
                      </NavLink>
                    </Grid>

                    <Grid item>
                      <ListItem>
                        <UserAvatar size="small" />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              {/* </Hidden> */}

              <Box
                // className={classes.drawer}
                sx={{
                  // width: {xs: 0, sm: drawerWidth},
                  width: { xs: 0 },
                  flexShrink: 0,
                  // [theme.breakpoints.up("sm")]: {
                  //   width: drawerWidth,
                  //   flexShrink: 0,
                  // },
                }}
                styles={{
                  backgroundColor: "blue",
                }}
                aria-label="mailbox folders"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  // classes={{
                  //   paper: classes.drawerPaper,
                  // }}
                  styles={{
                    width: drawerWidth,
                    backgroundColor: "#f6f6f6",
                    backgroundColor: "red !important",
                    borderRightWidth: "0px",
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  style={{ borderRight: "0px solid white" }}
                  BackdropProps={{ invisible: true }}
                >
                  {drawer(anchor)}
                </Drawer>
              </Box>
              <Box sx={{
  display: 'flex',            // Använder Flexbox för layouten
  flexDirection: 'column',    // Elementen ordnas vertikalt
  justifyContent: 'center',   // Centrerar innehållet vertikalt
  alignItems: 'center',       // Centrerar innehållet horisontellt
  flexGrow: 1,                // Boxen växer och tar upp tillgängligt utrymme
}}>
                {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}
                <div />
                {/* Exists to put down from the header */}
                <div
                          style={{
                            height: "50px",
                            width: "155px",
                            margin: "8px 8px 8px 4px",
                            top: "57px",
                            left: "58px",
                            
                          }}
                        />
                {/* </Hidden> */}
                <Routes>
                  <Route path="/testMating" element={<TestMatingView />}></Route>
                  <Route path="/rats" element={<RatsView />}></Route>
                  <Route path="/ratCrud" element={<RatCrudView />}></Route>
                  <Route path="/rat/:id" element={<RatView />}></Route>
                  
                  <Route 
                    path="/ratLogs/:id" 
                    element={ApplicationState.isAdmin() ? <RatLogView /> : <AccessDeniedView />}
                  />

                  <Route path="/rabbits" element={<RabbitsView />}></Route>
                  <Route path="/rabbitCrud" element={<RabbitCrudView />}></Route>
                  <Route path="/rabbit/:id" element={<RabbitView />}></Route>
                  
                  <Route 
                    path="/rabbitLogs/:id" 
                    element={ApplicationState.isAdmin() ? <RabbitLogView /> : <AccessDeniedView />}
                  />

                  <Route 
                    path="/users" 
                    element={ApplicationState.isAdmin() ? <UsersView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/userCrud" 
                    element={ApplicationState.isAdmin() ? <UserCrudView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/user/:id" 
                    element={ApplicationState.isAdmin() ? <UserView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/userLogs/:id" 
                    element={ApplicationState.isAdmin() ? <UserLogView /> : <AccessDeniedView />}
                  />

                  <Route 
                    path="/breeders" 
                    element={<BreedersView />}
                  />
                  <Route 
                    path="/breederCrud" 
                    element={ApplicationState.isAdmin() ? <BreederCrudView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/breeder/:id" 
                    element={<BreederView />}
                  />
                  <Route 
                    path="/breederLogs/:id" 
                    element={ApplicationState.isAdmin() ? <BreederLogView /> : <AccessDeniedView />}
                  />

                  <Route 
                    path="/litters" 
                    element={<LittersView />}
                  />
                  <Route 
                    path="/litterCrud" 
                    element={<LitterCrudView />}
                  />
                  <Route 
                    path="/litter/:id" 
                    element={<LitterView />}
                  />
                  <Route 
                    path="/litterLogs/:id" 
                    element={ApplicationState.isAdmin() ? <LitterLogView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/litterBaby/:id" 
                    element={<LitterBabyView />}
                  />
                  <Route 
                    path="/litterBabyCrud" 
                    element={<LitterBabyCrudView />}
                  />

                  <Route 
                    path="/memberships" 
                    element={ApplicationState.isAdmin() ? <MembershipsView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/membershipCrud" 
                    element={ApplicationState.isAdmin() ? <MembershipCrudView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/membership/:id" 
                    element={ApplicationState.isAdmin() ? <MembershipView /> : <AccessDeniedView />}
                  />

                  <Route 
                    path="/petRegistrations" 
                    element={ApplicationState.isAdmin() ? <PetRegistrationsView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/petRegistration/:id" 
                    element={ApplicationState.isAdmin() ? <PetRegistrationView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/petRegistrationLogs/:id" 
                    element={ApplicationState.isAdmin() ? <PetRegistrationLogView /> : <AccessDeniedView />}
                  />
                  <Route 
                    path="/admin" 
                    element={ApplicationState.isAdmin() ? <AdminToDoView /> : <AccessDeniedView />}
                  />
                  <Route path="/home" element={<HomeView />}></Route>
                  <Route path="/myPage" element={<MyPageView />}></Route>
                  <Route path="/faq" element={<FAQView />}></Route>
                  <Route path="/error" element={<ErrorPage />}></Route>
                  <Route path="/" element={<HomeView />}></Route>
                  <Route path="*" element={<HomeView />}></Route>
                </Routes>
              </Box>
            </React.Fragment>
          ))}
        </div>
      </ErrorBoundary>
      </Router>
    </>
  );
}
