import GenericLogView from './GenericLogView';
import DjurliAPI from '../DjurliAPI';

const fieldMappings = {
  comment: "Kommentar",
  createdAt: "Skapad",
  name: "Namn",
  color: "Färg",
  furType: "Päls",
  breederId: "UppfödarId",
  breederText: "Uppfödare",
  dateOfBirth: "Födelsedatum",
  dateOfDeath: "Dödsdatum",
  causeOfDeath: "Dödsorsak",
  earType: "Öron",
  fatherId: "FarId",
  fatherText: "Far",
  identificationNumber: "RegNr",
  markings: "Teckning",
  motherId: "MorId",
  motherText: "Mor",
  ownerText: "Ägare",
  ownerUserId: "ÄgarId",
  sex: "Kön",
};

const RabbitLogView = () => (
  <GenericLogView
    getLogsFunction={DjurliAPI.getLogsForRabbit.bind(DjurliAPI)}
    getObjectFunction={DjurliAPI.getRabbit.bind(DjurliAPI)}
    fieldMappings={fieldMappings}
    objectName="kaninen"
    objectNameField="name"
  />
);

export default RabbitLogView;