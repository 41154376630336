import React, { useEffect, useState } from "react";
import DjurliAPI from "../DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Grid,
  Typography,
  Card,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LitterDto from "../Dtos/LitterDto";
import ApplicationState from "../ApplicationState";
import CustomTextField from "./CustomTextField";
import GenericAutocomplete from "./GenericAutocomplete";
import BreederHelper from "./BreederHelper";

function LitterCrudView(props) {
  let history = useNavigate();

  const [title, setTitle] = useState("Ny Kull");
  const [comment, setComment] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [babiesAtBirth, setBabiesAtBirth] = useState("");
  const [babiesAfterThreeWeeks, setBabiesAfterThreeWeeks] = useState("");
  const [fathers, setFathers] = useState([]);
  const [father, setFather] = useState(null);
  const [mothers, setMothers] = useState([]);
  const [mother, setMother] = useState(null);
  const [breeders, setBreeders] = useState([]);
  const [breeder, setBreeder] = useState(null);

  const [commentError, setCommentError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [babiesAtBirthError, setBabiesAtBirthError] = useState("");
  const [babiesAfterThreeWeeksError, setBabiesAfterThreeWeeksError] = useState("");
  const [fatherError, setFatherError] = useState("");
  const [motherError, setMotherError] = useState("");
  const [breederError, setBreederError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const load1 = async () => {
      try {
        let fathers = await DjurliAPI.getPossibleFathers(ApplicationState.getToken());
        let mothers = await DjurliAPI.getPossibleMothers(ApplicationState.getToken());
        setFathers(fathers);
        setMothers(mothers);
        let breeders = await DjurliAPI.getBreeders(ApplicationState.getToken());
        setBreeders(breeders);

        let litterToEdit = ApplicationState.getLitterToEdit();
        if (litterToEdit) {
          setTitle("Redigera Kull");
          setComment(litterToEdit.comment);
          setDateOfBirth(
            new Date(litterToEdit.birthDate).toLocaleDateString("sv-SE")
          );
          setBabiesAtBirth(litterToEdit.babiesAtBirth);
          setBabiesAfterThreeWeeks(litterToEdit.babiesAfterThreeWeeks);
          let mother = mothers.find((pet) => pet.id === litterToEdit.motherId);
          setMother(mother);
          let father = fathers.find((pet) => pet.id === litterToEdit.fatherId);
          setFather(father);
          let breeder = breeders.find(
            (breeder) => breeder.id === litterToEdit.breederId
          );
          setBreeder(breeder);
        }
      } catch (error) {
        history("/error", { state: { errorMessage: error.message } });
      }
    };
    load1();
  }, []);

  const validateControls = () => {
    let valid = true;
    if (!validateComment()) {
      valid = false;
    }
    if (!validateDateOfBirth()) {
      valid = false;
    }
    if (!validateBabiesAtBirth()) {
      valid = false;
    }
    if (!validateBabiesAfterThreeWeeks()) {
      valid = false;
    }
    if (!validateFather()) {
      valid = false;
    }
    if (!validateMother()) {
      valid = false;
    }
    if (!validateBreeder()) {
      valid = false;
    }
    return valid;
  };

  function validateComment() {
    setCommentError("");
    return true;
  }

  function validateDateOfBirth() {
    if (dateOfBirth.length < 1) {
      setDateOfBirthError("Födelsedatum måste anges.");
      return false;
    } else {
      setDateOfBirthError("");
      return true;
    }
  }

  function validateBabiesAtBirth() {
    if (babiesAtBirth.length < 1) {
      setBabiesAtBirthError("Antal ungar måste anges.");
      return false;
    } else {
      setBabiesAtBirthError("");
      return true;
    }
  }

  function validateBabiesAfterThreeWeeks() {
    if (babiesAfterThreeWeeks.length < 1) {
      setBabiesAfterThreeWeeksError("Antal ungar efter tre veckor måste anges.");
      return false;
    } else {
      setBabiesAfterThreeWeeksError("");
      return true;
    }
  }

  function validateFather() {
    if (!father) {
      setFatherError("Far måste anges.");
      return false;
    }
    setFatherError("");
    return true;
  }

  function validateMother() {
    if (!mother) {
      setMotherError("Mor måste anges.");
      return false;
    }
    setMotherError("");
    return true;
  }

  function validateBreeder() {
    if (!breeder) {
      setBreederError("Uppfödare måste anges.");
      return false;
    }
    setBreederError("");
    return true;
  }

  return (
    <Box p={2} sx={{maxWidth: 600}}>
      <Card elevation={3} style={{ backgroundColor: "#f9f4f9" }}>
        <Box p={2}>
          <Typography
            variant={"h5"}
            component="h1"
            sx={{
              color: "#4A0E4E",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            p: isMobile ? 2 : 3,
            bgcolor: "white",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        >
          <CustomTextField
            id="dateOfBirthTextField"
            type="date"
            label="Födelsedatum"
            value={dateOfBirth}
            error={Boolean(dateOfBirthError)}
            helperText={dateOfBirthError}
            onChange={(event) => {
              setDateOfBirth(event.target.value);
            }}
            onBlur={validateDateOfBirth}
          />
           <CustomTextField
            id="babiesAtBirthTextField"
            label="Antal ungar"
            type="number"
            maxWidth={600}
            value={babiesAtBirth}
            error={Boolean(babiesAtBirthError)}
            helperText={babiesAtBirthError}
            onChange={(event) => {
              setBabiesAtBirth(event.target.value);
            }}
            onBlur={validateBabiesAtBirth}
            inputProps={{ min: 0 }}  // Detta förhindrar negativa värden
          />
          <CustomTextField
            id="babiesAfterThreeWeeksTextField"
            label="Antal ungar efter tre veckor"
            type="number"
            maxWidth={600}
            value={babiesAfterThreeWeeks}
            error={Boolean(babiesAfterThreeWeeksError)}
            helperText={babiesAfterThreeWeeksError}
            onChange={(event) => {
              setBabiesAfterThreeWeeks(event.target.value);
            }}
            onBlur={validateBabiesAfterThreeWeeks}
            inputProps={{ min: 0 }}  // Detta förhindrar negativa värden
          />

        <GenericAutocomplete
          id="mothers"
          options={mothers}
          value={mother}
          onChange={(event, newValue) => {
            setMother(newValue);
          }}
          getOptionLabel={(option) => option.name}
          label="Mor"
          primaryOptionProperty="name"
          secondaryOptionProperty="color"
          error={Boolean(motherError)}
          helperText={motherError}
        />

        <GenericAutocomplete
          id="fathers"
          options={fathers}
          value={father}
          onChange={(event, newValue) => {
            setFather(newValue);
          }}
          getOptionLabel={(option) => option.name}
          label="Far"
          primaryOptionProperty="name"
          secondaryOptionProperty="color"
          error={Boolean(fatherError)}
          helperText={fatherError}
        />

        <GenericAutocomplete
          id="breeders"
          options={breeders}
          value={breeder}
          onChange={(event, newValue) => {
            setBreeder(newValue);
          }}
          getOptionLabel={(option) => BreederHelper.getTitleString(option)}
          label="Uppfödare"
          primaryOptionProperty={(option) => BreederHelper.getTitleString(option)}
          secondaryOptionProperty="city"
          error={Boolean(breederError)}
          helperText={breederError}
        />

          <CustomTextField
            id="commentTextField"
            label="Kommentar"
            multiline={true}
            maxWidth={600}
            value={comment}
            error={Boolean(commentError)}
            helperText={commentError}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            onBlur={validateComment}
          />
        </Box>
        
        <Box p={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={async () => {
              try {
                if (!validateControls()) {
                  return;
                }
                let fatherIdToSave = undefined;
                let motherIdToSave = undefined;

                if (father) {
                  fatherIdToSave = father.id;
                }
                if (mother) {
                  motherIdToSave = mother.id;
                }

                const org = await DjurliAPI.getOrganization(ApplicationState.getToken());
                const petType = org.petType;
                console.log("TPE:", petType);
                let dto = new LitterDto(
                  DjurliAPI.generateGuid(),
                  fatherIdToSave,
                  motherIdToSave,
                  babiesAtBirth,
                  babiesAfterThreeWeeks,
                  breeder.id,
                  comment,
                  dateOfBirth,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  petType
                );

                let litterToEdit = ApplicationState.getLitterToEdit();
                if (litterToEdit) {
                  dto.id = litterToEdit.id;
                  dto.createdAt = litterToEdit.createdAt;
                  dto.createdBy = litterToEdit.createdBy;
                  dto.approvedAt = litterToEdit.approvedAt;
                  dto.approvedBy = litterToEdit.approvedBy;
                  dto.sentForApprovalAt = litterToEdit.sentForApprovalAt;
                  dto.sentForApprovalBy = litterToEdit.sentForApprovalBy;
                  dto.petType = litterToEdit.petType;
                  await DjurliAPI.updateLitter(
                    dto,
                    ApplicationState.getToken()
                  );
                } else {
                  await DjurliAPI.createLitter(
                    dto,
                    ApplicationState.getToken()
                  );
                }
                history("/litters");
              } catch (error) {
                history("/error", { state: { errorMessage: error.message } });
              }
            }}
          >
            Spara
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default LitterCrudView;