import { v4 as uuidv4 } from "uuid";
import { jwtDecode } from 'jwt-decode';
import ApplicationState from "./ApplicationState";

class DjurliAPI {
  static getBaseUrlApi() {
    const isProductionEnvironment = process.env.NODE_ENV === 'production';
    return isProductionEnvironment
      ? 'https://djurliapiv2.azurewebsites.net/'
      : 'https://localhost:7114/';
  }

  static getBaseUrlWebApp() {
    const isProductionEnvironment = process.env.NODE_ENV === 'production';
    return isProductionEnvironment
      ? 'https://app.djurli.com/'
      : 'http://localhost:3000/';
  }

  static getApiUrl(path) {
    return this.getBaseUrlApi() + path;
  }

  static generateGuid() {
    return uuidv4();
  }

  static isTokenExpired(jwtToken) {
    try {
      const decoded = jwtDecode(jwtToken);
      const currentTime = Date.now() / 1000; // Nuvarande tid i sekunder
      return decoded.exp < currentTime;
    } catch (e) {
      console.error('Fel vid kontroll av token:', e);
      return true;
    }
  }

  static handleInvalidToken() {
    ApplicationState.logout();
    window.location.href = '/login';
  }

  static async fetchWithAuth(endpoint, method = 'GET', jwtToken, body = null, responseType = 'json') {
    const options = {
      method,
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    };

    if (body && (method === 'POST' || method === 'PUT')) {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(body);
    }

    if (body && (method === 'POST' || method === 'PUT')) {
      options.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(this.getApiUrl(endpoint), options);

      if (response.status === 401) {
        if (this.isTokenExpired(jwtToken)) {
          this.handleInvalidToken();
          throw new Error('Token har gått ut');
        }
        throw new Error('Ogiltig token');
      }

      if (!response.ok) {
        let errorMessage = `HTTP-fel ${response.status}: ${response.statusText}`;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const errorData = await response.json();
          if (errorData.message) {
            errorMessage += ` - ${errorData.message}`;
          } else {
            errorMessage += ` - ${JSON.stringify(errorData)}`;
          }
        } else {
          const textContent = await response.text();
          if (textContent) {
            errorMessage += ` - ${textContent}`;
          }
        }
        throw new Error(errorMessage);
      }

      // För DELETE-anrop eller andra anrop som kanske inte returnerar något innehåll
      if (method === 'DELETE' || method === 'PUT' || method === 'POST' || response.status === 204) {
        return null;
      }

      switch (responseType) {
        case 'blob':
          return await response.blob();
        case 'arrayBuffer':
          return await response.arrayBuffer();
        case 'text':
          return await response.text();
        case 'json':
        default:
          return await response.json();
      }
    } catch (error) {
      console.error('Fel vid API-anrop:', error);
      throw new Error(`Fel vid anrop till ${endpoint}: ${error.message}\n\nStacktrace: ${error.stack}`);
    }
  }

  static async getDto(controller, id, jwtToken) {
    return await this.fetchWithAuth(`${controller}/${id}`, 'GET', jwtToken);
  }

  static async getDtos(controller, jwtToken) {
    return await this.fetchWithAuth(controller, 'GET', jwtToken);
  }

  static async postDto(controller, dto, jwtToken) {
    return await this.fetchWithAuth(controller, 'POST', jwtToken, dto);
  }

  static async putDto(controller, dto, jwtToken) {
    return await this.fetchWithAuth(`${controller}/${dto.id}`, 'PUT', jwtToken, dto);
  }

  static async deleteDto(controller, id, jwtToken) {
    return await this.fetchWithAuth(`${controller}/${id}`, 'DELETE', jwtToken);
  }

  static async getRats(jwtToken) {
    return this.getDtos("rats", jwtToken);
  }

  static async getRat(id, jwtToken) {
    return this.getDto("rats", id, jwtToken);
  }

  static async getOffspringsForPet(id, jwtToken) {
    return this.fetchWithAuth(`pets/OffspringsForPet/${id}`, 'GET', jwtToken);
  }

  static async createRat(dto, jwtToken) {
    await this.postDto("rats", dto, jwtToken);
  }

  static async updateRat(dto, jwtToken) {
    await this.putDto("rats", dto, jwtToken);
  }

  static async deleteRat(id, jwtToken) {
    await this.deleteDto("rats", id, jwtToken);
  }

  static async getUsers(jwtToken) {
    return this.getDtos("users", jwtToken);
  }

  static async getUser(id, jwtToken) {
    return this.getDto("users", id, jwtToken);
  }

  static async createUser(dto, jwtToken) {
    await this.postDto("users",dto, jwtToken);
  }

  static async updateUser(dto, jwtToken) {
    await this.putDto("users",dto, jwtToken);
  }

  static async deleteUser(id, jwtToken) {
    await this.deleteDto("users", id, jwtToken);
  }

  static async getBreeders(jwtToken) {
    return this.getDtos("breeders", jwtToken);
  }

  static async getBreeder(id, jwtToken) {
    return this.getDto("breeders", id, jwtToken);
  }

  static async createBreeder(dto, jwtToken) {
    await this.postDto("breeders",dto, jwtToken);
  }

  static async updateBreeder(dto, jwtToken) {
    await this.putDto("breeders",dto, jwtToken);
  }

  static async deleteBreeder(id, jwtToken) {
    await this.deleteDto("breeders", id, jwtToken);
  }

  static async getLitters(jwtToken) {
    return this.getDtos("litters", jwtToken);
  }

  static async getLitter(id, jwtToken) {
    return this.getDto("litters", id, jwtToken);
  }

  static async createLitter(dto, jwtToken) {
    await this.postDto("litters",dto, jwtToken);
  }

  static async updateLitter(dto, jwtToken) {
    await this.putDto("litters",dto, jwtToken);
  }

  static async deleteLitter(id, jwtToken) {
    await this.deleteDto("litters", id, jwtToken);
  }

  static async getMemberships(jwtToken) {
    return this.getDtos("memberships", jwtToken);
  }

  static async getMembership(id, jwtToken) {
    return this.getDto("memberships", id, jwtToken);
  }

  static async createMembership(dto, jwtToken) {
    await this.postDto("memberships",dto, jwtToken);
  }

  static async updateMembership(dto, jwtToken) {
    await this.putDto("memberships",dto, jwtToken);
  }

  static async deleteMembership(id, jwtToken) {
    await this.deleteDto("memberships",id, jwtToken);
  }

  static async calculateInbreedingCoefficient(motherId, fatherId, jwtToken) {
    return this.fetchWithAuth(`pets/InbreedingCoefficient/${motherId}/${fatherId}?baseUrl=${this.getBaseUrlWebApp()}`, 'GET', jwtToken);
  }

  static async getFamilyTreeForPet(id, jwtToken) {
    return this.fetchWithAuth(`pets/FamilyTree/${id}?baseUrl=${this.getBaseUrlWebApp()}`, 'GET', jwtToken);
  }

  static async approvePetRegistration(petRegistrationId, jwtToken) {
    await this.fetchWithAuth(`petRegistrations/${petRegistrationId}/Approve`, 'PUT', jwtToken);
  }

  static async revokeApprovalForPetRegistration(petRegistrationId, jwtToken) {
    await this.fetchWithAuth(`petRegistrations/${petRegistrationId}/RevokeApproval`, 'PUT', jwtToken);
  }

  static async getPetRegistrations(jwtToken) {
    return this.getDtos("petRegistrations", jwtToken);
  }

  static async getPetRegistration(id, jwtToken) {
    return this.getDto("petRegistrations", id, jwtToken);
  }

  static async getPetRegistrationByPetId(id, jwtToken) {
    return this.fetchWithAuth(`petRegistrations/byPetId/${id}`, 'GET', jwtToken);
  }

  static async getLogsForRat(id, jwtToken) {
    return this.fetchWithAuth(`logs/forRat/${id}`, 'GET', jwtToken);
  }

  static async getLogsForRabbit(id, jwtToken) {
    return this.fetchWithAuth(`logs/forRabbit/${id}`, 'GET', jwtToken);
  }

  static async getLogsForUser(id, jwtToken) {
    return this.fetchWithAuth(`logs/forUser/${id}`, 'GET', jwtToken);
  }

  static async getLogsForBreeder(id, jwtToken) {
    return this.fetchWithAuth(`logs/forBreeder/${id}`, 'GET', jwtToken);
  }

  static async getLogsForLitter(id, jwtToken) {
    return this.fetchWithAuth(`logs/forLitter/${id}`, 'GET', jwtToken);
  }

  static async getLogsForMembership(id, jwtToken) {
    return this.fetchWithAuth(`logs/forMembership/${id}`, 'GET', jwtToken);
  }

  static async getLogsForPetRegistration(id, jwtToken) {
    return this.fetchWithAuth(`logs/forPetRegistration/${id}`, 'GET', jwtToken);
  }

  static async createPetRegistration(dto, jwtToken) {
    await this.postDto("petRegistrations",dto, jwtToken);
  }

  static async updatePetRegistration(dto, jwtToken) {
    await this.putDto("petRegistrations",dto, jwtToken);
  }

  static async deletePetRegistration(id, jwtToken) {
    await this.deleteDto("petRegistrations", id, jwtToken);
  }

  static async getRatPdf(id, jwtToken) {
    return await this.fetchWithAuth(`pdfs/${id}`, 'GET', jwtToken, null, 'blob');
  }

  static async loginUser(email, loginMethod, loginToken) {
    return this.fetchWithAuth(`users/${email}/Login?loginMethod=${loginMethod}&loginToken=${loginToken}`, 'GET', null);
  }

  static async sendLoginCode(email) {
    return this.fetchWithAuth(`users/${email}/sendLoginCode`, 'GET', null, null, 'text');
  }

  static async approveLitter(litterId, jwtToken) {
    await this.fetchWithAuth(`litters/${litterId}/Approve`, 'PUT', jwtToken);
  }

  static async revokeApprovalForLitter(litterId, jwtToken) {
    await this.fetchWithAuth(`litters/${litterId}/RevokeApproval`, 'PUT', jwtToken);
  }

  static async sendLitterForApproval(litterId, jwtToken) {
    await this.fetchWithAuth(`litters/${litterId}/SendForApproval`, 'PUT', jwtToken);
  }

  static async revokeLitterSentForApproval(litterId, jwtToken) {
    await this.fetchWithAuth(`litters/${litterId}/RevokeSentForApproval`, 'PUT', jwtToken);
  }

  static async getLitterBabiesForLitter(litterId, jwtToken) {
    return this.fetchWithAuth(`litterBabies/forLitter/${litterId}`, 'GET', jwtToken);
  }

  static async getLitterBaby(id, jwtToken) {
    return this.getDto("litterBabies", id, jwtToken);
  }

  static async createLitterBaby(dto, jwtToken) {
    await this.postDto("litterBabies",dto, jwtToken);
  }

  static async updateLitterBaby(dto, jwtToken) {
    await this.putDto("litterBabies",dto, jwtToken);
  }

  static async deleteLitterBaby(id, jwtToken) {
    await this.deleteDto("litterBabies", id, jwtToken);
  }

  static async getRatsForUser(userId, jwtToken) {
    return this.fetchWithAuth(`rats/forUser/${userId}`, 'GET', jwtToken);
  }

  static async getLittersForUser(userId, jwtToken) {
    return this.fetchWithAuth(`litters/forUser/${userId}`, 'GET', jwtToken);
  }

  static async getLittersToApprove(jwtToken) {
    return this.fetchWithAuth('litters/toApprove', 'GET', jwtToken);
  }

  static async getPetRegistrationsToApprove(jwtToken) {
    return this.fetchWithAuth('petRegistrations/toApprove', 'GET', jwtToken);
  }

  static async getOrganization(jwtToken) {
    return this.fetchWithAuth('organizations/currentOrgForUser', 'GET', jwtToken);
  }

  static async getPet(id, jwtToken) {
    return this.getDto("pets", id, jwtToken);
  }

  static async getPossibleMothers(jwtToken) {
    return this.getDtos("pets/mothers", jwtToken);
  }

  static async getPossibleFathers(jwtToken) {
    return this.getDtos("pets/fathers", jwtToken);
  }

  static async getRabbit(id, jwtToken) {
    return this.getDto("rabbits", id, jwtToken);
  }

  static async getRabbits(jwtToken) {
    return this.getDtos("rabbits", jwtToken);
  }

  static async getRabbitsForUser(userId, jwtToken) {
    return this.fetchWithAuth(`rabbits/forUser/${userId}`, 'GET', jwtToken);
  }

  static async createRabbit(dto, jwtToken) {
    await this.postDto("rabbits",dto, jwtToken);
  }

  static async updateRabbit(dto, jwtToken) {
    await this.putDto("rabbits",dto, jwtToken);
  }

  static async deleteRabbit(id, jwtToken) {
    await this.deleteDto("rabbits", id, jwtToken);
  }
}

export default DjurliAPI;