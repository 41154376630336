import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenericObjectView from "./GenericObjectView";
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import EditIcon from "@mui/icons-material/Edit";
import { Typography } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BreederHelper from "./BreederHelper";

const BreederView = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [breederData, setBreederData] = useState(null);
  const [ownerData, setOwnerData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadBreederData = useCallback(async () => {
    setLoading(true);
    try {
      const breeder = await DjurliAPI.getBreeder(id, ApplicationState.getToken());
      setBreederData(breeder);
      if (breeder.ownerUserId) {
        const owner = await DjurliAPI.getUser(breeder.ownerUserId, ApplicationState.getToken());
        setOwnerData(owner);
      }
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  }, [id, history]);

  useEffect(() => {
    loadBreederData();
  }, [loadBreederData]);

  const deleteBreeder = async (id, token) => {
    await DjurliAPI.deleteBreeder(id, token);
    history("/breeders");
  };

  const getTitleString = (breeder) => {
    return BreederHelper.getTitleString(breeder, ownerData);
  };

  const getGroupedData = (breeder) => {
    if (!breeder) return [];
    return [
      {
        title: "Grundläggande information",
        data: [
          { label: "Prefix", value: breeder.prefix },
          { label: "Ägare", value: ownerData ? `${ownerData.firstName} ${ownerData.lastName}` : breeder.ownerText || "N/A" },
          { label: "Ort", value: breeder.city },
          { label: "Beskrivning", value: breeder.description },
        ]
      },
    ];
  };

  const handleEditAction = () => {
    if (breederData) {
      ApplicationState.setBreederToEdit(breederData);
      history("/breederCrud");
    } else {
      console.error("Försökte redigera en odefinierad uppfödare");
    }
  };

  const additionalMenuItems = [
    {
      label: "Logg",
      icon: AssignmentIcon,
      onClick: () => {
        history("/breederLogs/" + breederData.id);
      },
      condition: () => ApplicationState.isAdmin(),
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!breederData) {
    return <Typography>Ingen uppfödardata tillgänglig.</Typography>;
  }

  return (
    <GenericObjectView
      deleteRedirectPath="breeders"
      objectTypeText="uppfödare"
      object={breederData}
      deleteObject={deleteBreeder}
      getTitleString={getTitleString}
      getGroupedData={getGroupedData}
      apiToken={ApplicationState.getToken()}
      onMainAction={handleEditAction}
      mainActionIcon={<EditIcon />}
      mainActionText="Redigera"
      additionalMenuItems={additionalMenuItems}
    />
  );
};

export default BreederView;