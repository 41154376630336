import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DjurliAPI from "../DjurliAPI";
// import DjurliAPI from "./DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import ApplicationState from "../ApplicationState";

function MembershipsView(props) {
  let history = useNavigate();

  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      setLoading(true);
      let le = await DjurliAPI.getMemberships(ApplicationState.getToken());
      le = le.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setMemberships(le);
      setLoading(false);
    };
    load1();
  }, []);

  const filteredMemberships = memberships.filter(
    (membership) =>
      searchTerm === "" ||
      membership.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      membership.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      membership.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box p={2}>
      <Card style={{ backgroundColor: "white", margin: "8px" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4" style={{ margin: "16px" }}>
            Medlemskap
          </Typography>
          <Box m={2}>
            <Button
              variant="outlined"
              onClick={async () => {
                ApplicationState.setMembershipToEdit(undefined);
                history("/membershipCrud");
              }}
            >
              Nytt medlemskap
            </Button>
          </Box>
        </Grid>
        <Box m={1}>
          <Grid container>
            <TextField
              fullWidth
              label="Sök medlemskap"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ margin: "8px" }}
            />
          </Grid>
        </Box>
      </Card>
      <Card style={{ backgroundColor: "white", margin: "8px" }}>
        {!loading && (
          <List style={{ backgroundColor: "white" }}>
            {filteredMemberships.map((element) => {
              return (
                <ListItem key={element.id} button>
                  <CardActionArea
                    onClick={() => {
                      history("/membership/" + element.id);
                    }}
                  >
                    <ListItemText>
                      {element.startDate} {element.endDate}
                    </ListItemText>
                    <ListItemText>
                      <Typography variant="body2" color="textSecondary">
                        {element.email}
                      </Typography>
                    </ListItemText>
                  </CardActionArea>
                  <br />
                </ListItem>
              );
            })}
            {filteredMemberships.length === 0 && (
              <Box p={2}>
                <Typography variant="body1">
                  Inga medlemskap matchar sökkriteriet
                </Typography>
              </Box>
            )}
          </List>
        )}
        {loading && (
          <Box p={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: "8px" }}
            >
              <CircularProgress></CircularProgress>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="body1">Laddar...</Typography>
            </Grid>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default MembershipsView;
