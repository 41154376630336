import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import GenericObjectView from './GenericObjectView';
import DjurliAPI from '../DjurliAPI';
import ApplicationState from '../ApplicationState';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Typography } from "@mui/material";
import LoadingSpinner from './LoadingSpinner';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import GenericTable from './GenericTable';
import RatHelper from './RatHelper';

const LitterView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [litterData, setLitterData] = useState(null);
  const [babies, setBabies] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadLitterData = useCallback(async () => {
    setLoading(true);
    try {
      const litter = await DjurliAPI.getLitter(id, ApplicationState.getToken());
      
      if (litter.motherId) {
        litter.mother = await DjurliAPI.getPet(litter.motherId, ApplicationState.getToken());
      }
      if (litter.fatherId) {
        litter.father = await DjurliAPI.getPet(litter.fatherId, ApplicationState.getToken());
      }
      if (litter.breederId) {
        litter.breeder = await DjurliAPI.getBreeder(litter.breederId, ApplicationState.getToken());
      }
      
      setLitterData(litter);

      const babies = await DjurliAPI.getLitterBabiesForLitter(litter.id, ApplicationState.getToken());
      setBabies(babies.sort((a, b) => a.name.localeCompare(b.name)));

    } catch (error) {
      navigate("/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    loadLitterData();
  }, [loadLitterData]);

  const deleteLitter = async (id, token) => {
    await DjurliAPI.deleteLitter(id, token);
    navigate("/litters");
  };

  const getTitleString = (litter) => {
    return `${litter.father?.name} + ${litter.mother?.name} ${new Date(litter.birthDate).toLocaleDateString("sv-SE")}-kullen`;
  };

  const getGroupedData = (litter) => [
    {
      title: "Grundläggande information",
      data: [
        { label: "Födelsedatum", value: new Date(litter.birthDate).toLocaleDateString("sv-SE") },
        { label: "Uppfödare", value: litter.breeder?.prefix },
        { label: "Far", value: litter.father?.name },
        { label: "Mor", value: litter.mother?.name },
        { label: "Antal ungar vid födseln", value: litter.babiesAtBirth },
        { label: "Antal överlevande unger efter 3 veckor", value: litter.babiesAfterThreeWeeks },
        { label: "Kommentar", value: litter.comment },
        { label: "Inskickad för godkännande", value: litter.sentForApprovalAt ? new Date(litter.sentForApprovalAt).toLocaleString("sv-SE") + " av " + litter.sentForApprovalByUser.firstName + " " + litter.sentForApprovalByUser.lastName: "Nej" },
        { label: "Godkänd", value: litter.approvedAt ? new Date(litter.approvedAt).toLocaleString("sv-SE") + " av " + litter.approvedByUser.firstName + " " + litter.approvedByUser.lastName : "Nej" },
      ]
    },
  ];

  const handleEditAction = () => {
    if (litterData) {
      ApplicationState.setLitterToEdit(litterData);
      navigate("/litterCrud");
    } else {
      console.error("Försökte redigera en odefinierad kull");
    }
  };

  const handleSubmitForApproval = async () => {
    try {
      await DjurliAPI.sendLitterForApproval(litterData.id, ApplicationState.getToken());
      await loadLitterData();
    } catch (error) {
      navigate("/error", { state: { errorMessage: error.message } });
    }
  };

  const handleApproveLitter = async () => {
    try {
      await DjurliAPI.approveLitter(litterData.id, ApplicationState.getToken());
      
      // Get the returnPage from URL parameters
      const searchParams = new URLSearchParams(location.search);
      const returnPath = searchParams.get('returnPath');
      console.log("returnPath", returnPath);
      if (returnPath) {
        // Redirect to the specified return page
        navigate(returnPath);
      } else {
        // If no returnPage is specified, reload the current page
        await loadLitterData();
      }
    } catch (error) {
      navigate("/error", { state: { errorMessage: error.message } });
    }
  };

  const handleUndoSubmitForApproval = async () => {
    try {
      await DjurliAPI.revokeLitterSentForApproval(litterData.id, ApplicationState.getToken());
      await loadLitterData();
    } catch (error) {
      navigate("/error", { state: { errorMessage: error.message } });
    }
  };

  const handleUndoApproveLitter = async () => {
    try {
      await DjurliAPI.revokeApprovalForLitter(litterData.id, ApplicationState.getToken());
      await loadLitterData();
    } catch (error) {
      navigate("/error", { state: { errorMessage: error.message } });
    }
  };

  const handleAddBaby = () => {
    ApplicationState.setLitterBabyToEdit(undefined);
    ApplicationState.setLitterToAddBabyFor(litterData);
    navigate(`/litterBabyCrud/`);
  };

  const isBreederForLitter = () => {
    if (!litterData || !litterData.breeder || !ApplicationState.getUser()) {
      return false;

    }    
    if(litterData.breeder.ownerUserId && litterData.breeder.ownerUserId === ApplicationState.getUser().id) {
      return true;
    }
    return false
  };

  const additionalMenuItems = [
    {
      label: "Lägg till unge",
      icon: AddIcon,
      onClick: handleAddBaby,
      condition: () => !litterData.sentForApprovalAt && !litterData.approvedAt && (ApplicationState.isAdmin() || isBreederForLitter()),
    },
    {
      label: "Skicka in för godkännande",
      icon: SendIcon,
      onClick: handleSubmitForApproval,
      condition: () => !litterData.sentForApprovalAt && !litterData.approvedAt && (ApplicationState.isAdmin() || isBreederForLitter()),
    },
    {
      label: "Ångra inskickande",
      icon: UndoIcon,
      onClick: handleUndoSubmitForApproval,
      condition: () => litterData.sentForApprovalAt && !litterData.approvedAt && ApplicationState.isAdmin(),
    },
    {
      label: "Godkänn",
      icon: CheckCircleIcon,
      onClick: handleApproveLitter,
      condition: () => litterData.sentForApprovalAt && !litterData.approvedAt && ApplicationState.isAdmin(),
    },
    {
      label: "Ångra godkännande",
      icon: UndoIcon,
      onClick: handleUndoApproveLitter,
      condition: () => litterData.approvedAt && ApplicationState.isAdmin(),
    },
    {
      label: "Logg",
      icon: AssignmentIcon,
      onClick: () => {
        navigate("/litterLogs/" + litterData.id);
      },
      condition: () => ApplicationState.isAdmin(),
    },
  ];

  const getAdditionalContentSections = useCallback(() => {
    if (!babies) return [];
    
    const columns = [
      { 
        id: 'name', 
        label: 'Namn',
        render: (row) => row.name
      },
      { 
        id: 'sex', 
        label: 'Kön',
        render: (row) => row.sex === "F" ? "Hona" : row.sex === "M" ? "Hane" : "?"
      },
      { 
        id: 'appearance', 
        label: 'Utseende',
        render: (row) => RatHelper.getAppearance(row)
      },
    ];

    const handleRowClick = (baby) => {
      navigate(`/litterBaby/${baby.id}`);
    };

    return [
      {
        title: "Ungar",
        content: (
          <GenericTable
            data={babies}
            columns={columns}
            onRowClick={handleRowClick}
            emptyMessage="Inga ungar har lagts till."
          />
        )
      }
    ];
  }, [babies, navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!litterData) {
    return <Typography>Ingen kulldata tillgänglig.</Typography>;
  }

  

  const additionalSections = getAdditionalContentSections();

  return (
    <GenericObjectView
      deleteRedirectPath="litters"
      objectTypeText="kull"
      object={litterData}
      deleteObject={deleteLitter}
      getTitleString={getTitleString}
      getGroupedData={getGroupedData}
      apiToken={ApplicationState.getToken()}
      onMainAction={handleEditAction}
      mainActionIcon={<EditIcon />}
      mainActionText="Redigera"
      additionalMenuItems={additionalMenuItems}
      additionalContentSections={additionalSections}
    />
  );
};

export default LitterView;