import React, { useState } from "react";
import GenericListView from './GenericListView';
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import { Box, Grid, Typography } from "@mui/material";

const AdminToDoView = () => {
  // PetRegistrations section
  const petRegistrationColumns = [
    { field: 'name', headerName: 'Namn', flex: 1, minWidth: 120 },
    { field: 'status', headerName: 'Status', flex: 1, minWidth: 100 },
    { field: 'year', headerName: 'År', flex: 1, minWidth: 80 },
    { field: 'generateIdentificationNumber', headerName: 'RegNr', flex: 1, minWidth: 80 },
  ];

  const transformPetRegistrationData = (petRegistrations) => petRegistrations
    .map(registration => ({
      id: registration.registration.id,
      name: registration.pet.name,
      status: "Ej godkänd",
      year: new Date(registration.registration.createdAt).getFullYear(),
      generateIdentificationNumber: registration.registration.generateIdentificationNumber ? "Med RegNr" : "Utan RegNr",
    }));

  // Litters section
  const litterColumns = [
    { field: 'prefix', headerName: 'Prefix', flex: 1, minWidth: 120 },
    { field: 'birthDate', headerName: 'Datum', flex: 1, minWidth: 100 },
    { field: 'father', headerName: 'Far', flex: 1, minWidth: 120 },
    { field: 'mother', headerName: 'Mor', flex: 1, minWidth: 120 },
  ];

  const transformLitterData = (litters) => litters
    .map(litter => ({
      id: litter.id,
      prefix: litter.breeder?.prefix || 'N/A',
      birthDate: litter.birthDate ? new Date(litter.birthDate).toLocaleDateString("sv-SE") : 'N/A',
      father: litter.father?.name || 'N/A',
      mother: litter.mother?.name || 'N/A'
    }));

  return (
    <>
        <GenericListView
          title="Råttregistreringar att godkänna"
          apiCall={() => DjurliAPI.getPetRegistrationsToApprove(ApplicationState.getToken())}
          columns={petRegistrationColumns}
          searchFields={['name', 'year']}
          detailPath="/petRegistration"
          transformData={transformPetRegistrationData}
          returnPath={"/admin"}
        />
    
        <GenericListView
          title="Kullar att godkänna"
          apiCall={() => DjurliAPI.getLittersToApprove(ApplicationState.getToken())}
          columns={litterColumns}
          searchFields={['prefix', 'father', 'mother']}
          detailPath="/litter"
          transformData={transformLitterData}
          returnPath={"/admin"}
        />
    </>
  );
};

export default AdminToDoView;