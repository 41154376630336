import React from 'react';
import { Box, Grid, CircularProgress, Typography } from '@mui/material';

const LoadingSpinner = () => {
  return (
    <Box p={2} mt={4}>
      <Grid container justifyContent="center" alignItems="center" style={{ paddingBottom: '8px' }}>
        <CircularProgress />
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="body1">Laddar...</Typography>
      </Grid>
    </Box>
  );
};

export default LoadingSpinner;
