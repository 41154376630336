import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenericObjectView from "./GenericObjectView";
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import EditIcon from "@mui/icons-material/Edit";
import { Typography } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import AssignmentIcon from "@mui/icons-material/Assignment";


const UserView = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadUserData = useCallback(async () => {
    setLoading(true);
    try {
      const user = await DjurliAPI.getUser(id, ApplicationState.getToken());
      setUserData(user);
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  }, [id, history]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  const deleteUser = async (id, token) => {
    await DjurliAPI.deleteUser(id, token);
    history("/users");
  };

  const getTitleString = (user) => {
    return user ? `${user.firstName} ${user.lastName}` : "Okänd användare";
  };

  const getGroupedData = (user) => {
    if (!user) return [];
    return [
      {
        title: "Grundläggande information",
        data: [
          { label: "Förnamn", value: user.firstName },
          { label: "Efternamn", value: user.lastName },
          { label: "E-post", value: user.email },
          { label: "Ort", value: user.city },
          { label: "Aktiv", value: user.active ? "Ja" : "Nej" },
          { label: "Roll", value: user.admin ? "Admin" : "Användare" },
          { label: "Skapad", value: new Date(user.createdAt).toLocaleDateString() },
        ]
      },
    ];
  };

  const handleEditAction = () => {
    if (userData) {
      ApplicationState.setUserToEdit(userData);
      history("/userCrud");
    } else {
      console.error("Försökte redigera en odefinierad användare");
    }
  };

  const additionalMenuItems = [
    {
      label: "Logg",
      icon: AssignmentIcon,
      onClick: () => {
        history("/userLogs/" + userData.id);
      },
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!userData) {
    return <Typography>Ingen användardata tillgänglig.</Typography>;
  }

  return (
    <GenericObjectView
      deleteRedirectPath="users"
      objectTypeText="användare"
      object={userData}
      deleteObject={deleteUser}
      getTitleString={getTitleString}
      getGroupedData={getGroupedData}
      apiToken={ApplicationState.getToken()}
      onMainAction={handleEditAction}
      mainActionIcon={<EditIcon />}
      mainActionText="Redigera"
      additionalMenuItems={additionalMenuItems}
    />
  );
};

export default UserView;