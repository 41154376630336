import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import GenericObjectView from './GenericObjectView';
import DjurliAPI from '../DjurliAPI';
import ApplicationState from '../ApplicationState';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from "@mui/material";
import LoadingSpinner from './LoadingSpinner';

const LitterBabyView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [litterBabyData, setLitterBabyData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadLitterBabyData = useCallback(async () => {
    setLoading(true);
    try {
      const litterBaby = await DjurliAPI.getLitterBaby(id, ApplicationState.getToken());
      
      if (litterBaby.litterId) {
        litterBaby.litter = await DjurliAPI.getLitter(litterBaby.litterId, ApplicationState.getToken());
      }
      
      setLitterBabyData(litterBaby);
    } catch (error) {
      navigate("/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    loadLitterBabyData();
  }, [loadLitterBabyData]);

  const deleteLitterBaby = async (id, token) => {
    await DjurliAPI.deleteLitterBaby(id, token);
    navigate("/litters");
  };

  const getTitleString = (litterBaby) => {
    return `${litterBaby.name} (${litterBaby.litter?.birthDate ? new Date(litterBaby.litter.birthDate).toLocaleDateString("sv-SE") : 'Okänt datum'}-kullen)`;
  };

  const getGroupedData = (litterBaby) => [
    {
      title: "Grundläggande information",
      data: [
        { label: "Namn", value: litterBaby.name },
        { 
          label: "Kön", 
          value: litterBaby.sex === 'F' ? 'Hona' : litterBaby.sex === 'M' ? 'Hane' : litterBaby.sex 
        },
        { label: "Färg", value: litterBaby.color },
        { label: "Teckning", value: litterBaby.markings },
        { label: "Pälstyp", value: litterBaby.furType },
        { label: "Örontyp", value: litterBaby.earType },
        { label: "Speciella kännetecken", value: litterBaby.distinctiveFeatures },
        { label: "Födelsedatum", value: litterBaby.litter?.birthDate ? new Date(litterBaby.litter.birthDate).toLocaleDateString("sv-SE") : 'Okänt' },
        { label: "Ägare", value: litterBaby.owner ? litterBaby.owner.firstName + ' ' + litterBaby.owner.lastName : '' },
        { label: "Kommentar", value: litterBaby.comment },
      ]
    },
  ];

  const handleEditAction = () => {
    if (litterBabyData) {
      ApplicationState.setLitterBabyToEdit(litterBabyData);
      navigate("/litterBabyCrud");
    } else {
      console.error("Försökte redigera en odefinierad kullunge");
    }
  };

  const additionalMenuItems = [
    {
      label: "Logg",
      icon: AssignmentIcon,
      onClick: () => {
        navigate("/litterBabyLogs/" + litterBabyData.id);
      },
      condition:() => ApplicationState.isAdmin(),
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!litterBabyData) {
    return <Typography>Ingen kullungedata tillgänglig.</Typography>;
  }

  return (
    <GenericObjectView
      deleteRedirectPath={"litter/" + litterBabyData.litterId}
      objectTypeText="kullunge"
      object={litterBabyData}
      deleteObject={deleteLitterBaby}
      getTitleString={getTitleString}
      getGroupedData={getGroupedData}
      apiToken={ApplicationState.getToken()}
      onMainAction={handleEditAction}
      mainActionIcon={<EditIcon />}
      mainActionText="Redigera"
      additionalMenuItems={additionalMenuItems}
    />
  );
};

export default LitterBabyView;