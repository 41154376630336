import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { 
  Box, Card, Typography, List, ListItem, Divider,
  useTheme, useMediaQuery, Collapse, IconButton
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ApplicationState from "../ApplicationState";

const normalizeString = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');

const getFieldName = (field, fieldMappings) => {
  const normalizedField = normalizeString(field);
  const matchingKey = Object.keys(fieldMappings).find(key => 
    normalizeString(key) === normalizedField
  );
  return matchingKey ? fieldMappings[matchingKey] : field;
};

const ValueChange = ({ field, oldValue, newValue, fieldMappings }) => {
  // Filtrera bort UpdatedAt och UpdatedBy
    if (field === 'UpdatedAt' || field === 'UpdatedBy') return null;
    return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#4A0E4E' }}>
        {getFieldName(field, fieldMappings)}
      </Typography>
      <Typography variant="body2" sx={{ color: '#666' }}>
        Gammalt värde: <span style={{ textDecoration: 'line-through' }}>
          {oldValue !== undefined && oldValue !== null && oldValue !== '' ? String(oldValue) : '(Inget tidigare värde)'}
        </span>
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 'medium', color: '#D6409B' }}>
        Nytt värde: {newValue !== undefined && newValue !== null && newValue !== '' ? String(newValue) : '(Tomt)'}
      </Typography>
    </Box>
    );
};

const LogItem = ({ log, isLast, fieldMappings }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const oldValues = JSON.parse(log.oldValues) || {};
  const newValues = JSON.parse(log.newValues);
  const changedFields = Object.keys({ ...oldValues, ...newValues }).filter(
    key => oldValues[key] !== newValues[key]
  );

  const toggleExpand = (event) => {
    if (event.target.closest('.log-details')) return;
    setExpanded(!expanded);
  };

  return (
    <>
      <ListItem 
        onClick={toggleExpand}
        sx={{ 
          flexDirection: 'column', 
          alignItems: 'flex-start', 
          py: 2, 
          cursor: 'pointer',
          '&:hover': { bgcolor: '#f9f4f9' },
          '&:active': { bgcolor: 'transparent' },
          userSelect: 'none',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1, mr: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'medium', color: '#4A0E4E' }}>
              {new Date(log.createdAt).toLocaleString('sv-SE', { dateStyle: 'short', timeStyle: 'short' })}
            </Typography>
            <Typography variant="body2" sx={{ color: '#666' }}>
              Ändrades av: {log.user.firstName} {log.user.lastName}
            </Typography>
          </Box>
          <IconButton 
            onClick={(e) => { 
              e.stopPropagation(); 
              setExpanded(!expanded); 
            }} 
            sx={{ 
              color: '#4A0E4E',
              padding: isMobile ? '8px' : '12px',
              '&:active': { bgcolor: 'transparent' },
            }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ width: '100%', mt: 2 }}>
          <Box className="log-details">
            {changedFields.map(field => (
              <ValueChange 
                key={field} 
                field={field} 
                oldValue={oldValues[field]} 
                newValue={newValues[field]} 
                fieldMappings={fieldMappings}
              />
            ))}
          </Box>
        </Collapse>
      </ListItem>
      {!isLast && <Divider />}
    </>
  );
};

const GenericLogView = ({ 
  getLogsFunction, 
  getObjectFunction, 
  fieldMappings, 
  objectName, 
  objectNameField = 'name'
}) => {
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [object, setObject] = useState({});
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadData = async () => {
      try {
        const token = ApplicationState.getToken();
        console.log('ID: ', id);
        let [logData, objectData] = await Promise.all([
          getLogsFunction(id, token),
          getObjectFunction(id, token)
        ]);
        console.log('Log data: ', logData);
        console.log('Object data: ', objectData);
        logData = logData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setLogs(logData);
        setObject(objectData);
      } catch (error) {
        navigate("/error", { state: { errorMessage: error.message } });
      }
    };
    loadData();
  }, [id, navigate, getLogsFunction, getObjectFunction]);

  return (
    <Box p={2} sx={{ width: "90%", margin: "0 auto" }}>
      <Card elevation={3} sx={{ bgcolor: 'white' }}>
        <Box 
          sx={{ 
            p: isMobile ? 2 : 3, 
            bgcolor: '#f0e6f0',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
          }}
        >
          <Typography 
            variant="h5" 
            component="h1" 
            sx={{ 
              color: '#4A0E4E', 
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            Loghändelser för {objectName}: <br /> {object[objectNameField]}
          </Typography>
        </Box>
        <List disablePadding>
          {logs.map((log, index) => (
            <LogItem 
              key={log.id} 
              log={log} 
              isLast={index === logs.length - 1} 
              fieldMappings={fieldMappings}
            />
          ))}
        </List>
      </Card>
    </Box>
  );
};

export default GenericLogView;