import React from 'react';
import { Autocomplete, Grid, TextField, Box, Typography } from '@mui/material';

const GenericAutocomplete = ({
  options,
  value,
  onChange,
  id,
  label,
  getOptionLabel,
  primaryOptionProperty,
  secondaryOptionProperty,
  maxWidth = 600,
  error = false,
  helperText = ''
}) => {
  return (
    <Autocomplete
      id={id}
      options={options}
      value={value}
      autoHighlight
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Grid container alignItems="center">
            <Grid item>
              <Box>
                <b>
                  {typeof primaryOptionProperty === 'function' 
                    ? primaryOptionProperty(option) 
                    : option[primaryOptionProperty]}
                </b>
              </Box>
              <Typography>
                <i>
                  {typeof secondaryOptionProperty === 'function' 
                    ? secondaryOptionProperty(option) 
                    : option[secondaryOptionProperty]}
                </i>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          error={error}
          helperText={helperText}
          sx={{
            margin: "8px 0",
            backgroundColor: "white",
            maxWidth: maxWidth,
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default GenericAutocomplete;