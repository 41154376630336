import React from "react";
import GenericListView from './GenericListView';
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import RatHelper from "./RatHelper";

function RatsView() {
  const columns = [
    { field: 'name', headerName: 'Namn', flex: 1, minWidth: 120 },
    { field: 'breeder', headerName: 'Uppfödare', flex: 1, minWidth: 80 },
    { field: 'identificationNumber', headerName: 'RegNr', flex: 1, minWidth: 80 },
    { field: 'appearance', headerName: 'Utseende', flex: 1, minWidth: 120 },
    { field: 'dateOfBirth', headerName: 'Födelsedatum', flex: 1, minWidth: 100 },
    { field: 'sex', headerName: 'Kön', flex: 1, minWidth: 80 },

  ];

  const transformData = (rats) => rats.map(rat => ({
    id: rat.id,
    name: rat.prefix ? `${rat.prefix} ${rat.name}` : rat.name,
    dateOfBirth: rat.dateOfBirth ? new Date(rat.dateOfBirth).toLocaleDateString("sv-SE") : '-',
    sex: rat.sex === 'F' ? 'Hona' : rat.sex === 'M' ? 'Hane' : '-',
    prefix: rat.prefix || '-',
    breeder: rat.breeder ? rat.breeder : '-',
    identificationNumber: rat.identificationNumber || '-',
    appearance: rat.appearance
  }));

  return (
    <GenericListView
      title="Råttor"
      newItemButtonText="Ny råtta"
      newItemPath="/ratCrud"
      apiCall={() => DjurliAPI.getRats(ApplicationState.getToken())}
      columns={columns}
      searchFields={['name', 'prefix', 'breeder', 'identificationNumber', 'dateOfBirth', 'sex']}
      detailPath="/rat"
      transformData={transformData}
      onNewItemClick={() => ApplicationState.setRatToEdit(undefined)}
    />
  );
}

export default RatsView;