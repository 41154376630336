import React from "react";
import GenericListView from './GenericListView';
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";

function UsersView() {
  const columns = [
    
    { 
      field: 'name', 
      headerName: 'Namn', 
      flex: 1, 
      minWidth: 150,
    },
    { field: 'email', headerName: 'E-post', flex: 1, minWidth: 200 },
    { field: 'admin', headerName: 'Roll', flex: 1, minWidth: 100 },
    
    { 
      field: 'active', 
      headerName: 'Aktiv', 
      flex: 1, 
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params.value ? (
            <PersonIcon fontSize="small" color="primary" />
          ) : (
            <PersonOffIcon fontSize="small" style={{ color: "#ddd" }} />
          )}
        </div>
      )
    },
    
  ];

  const transformData = (users) => users.map(user => ({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    active: user.active ? 'Aktiv' : 'Inaktiv',
    admin: user.admin ? 'Admin' : 'User'
  }));

  return (
    <GenericListView
      title="Användare"
      newItemButtonText="Ny användare"
      newItemPath="/userCrud"
      apiCall={() => DjurliAPI.getUsers(ApplicationState.getToken())}
      columns={columns}
      searchFields={['name', 'email']}
      detailPath="/user"
      transformData={transformData}
      onNewItemClick={() => ApplicationState.setUserToEdit(undefined)}
    />
  );
}

export default UsersView;