import React, { useEffect, useState } from "react";
import DjurliAPI from "../DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import EmailLoginButton from "./EmailLoginButton";
import logo from "../logo_text_below.svg";
import { Grid, Typography, Hidden } from "@mui/material";
import ApplicationState from "../ApplicationState";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ErrorBoundary from "./ErrorPage";
import GoogleLoginButton from "./GoogleLoginButton";
import EmailIcon from '@mui/icons-material/Email';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function LoginView(props) {
  let history = useNavigate();

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [sentRequestForCode, setSentRequestForCode] = useState(false);
  const [showEmailLogin, setShowEmailLogin] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  useEffect(() => {
    if (ApplicationState.getToken() && ApplicationState.getToken().length > 0) {
      history("/rats");
    }
  }, []);

  return (
    
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: "100vh",
          background:
            "linear-gradient(135deg, #000000, #EB71A9)",
        }}
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img
            src={logo}
            style={{ height: 312, margin: "10px 0" }}
            alt="Logo"
          />
        </Grid>

        <Divider
          style={{
            width: "168px",
            marginBottom: "28px",
            marginTop: "12px",
            backgroundColor: "white",
          }}
        />

        <Box p="8px" m="8px" style={{ textAlign: 'center' }}>
          {!showEmailLogin ? (
            <>
              <Button
                variant="contained"
                onClick={() => setShowEmailLogin(true)}
                style={{ 
                  margin: "8px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  backgroundColor: "white",
                  color: "black",
                  '&:hover': {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                startIcon={<EmailIcon />}
              >
                Logga in via email
              </Button>
              <Box p="8px" m="8px">
                <GoogleLoginButton
                  style={{ padding: "4px" }}
                  doLoginFunction={props.doLoginFunction}
                />
              </Box>
            </>
          ) : (
            <>
              <TextField
                id="emailTextField"
                type="text"
                style={{ margin: "8px", color: "white" }}
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  style: { color: "white" },
                }}
                sx={{
                  "& label": { color: "white" },
                  "& label.Mui-focused": { color: "white" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "white" },
                    "&:hover fieldset": { borderColor: "white" },
                    "&.Mui-focused fieldset": { borderColor: "white" },
                  },
                  "& .MuiInput-underline:after": { borderBottomColor: "white" },
                }}
              />
              <br />
              {!sentRequestForCode && (
                <Button
                  style={{ margin: "8px" }}
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    if (email.length === 0) {
                      alert("Du måste ange en emailadress");
                      return;
                    }
                    try {
                      await DjurliAPI.sendLoginCode(email);
                    } catch (error) {
                      history("/error", { state: { errorMessage: error.message } });
                    }
                    setSentRequestForCode(true);
                  }}
                >
                  Skicka kod
                </Button>
              )}
              {sentRequestForCode && (
                <>
                  <TextField
                    id="codeTextField"
                    type="text"
                    style={{ margin: "8px" }}
                    label="Code"
                    variant="outlined"
                    value={code}
                    onChange={handleCodeChange}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    InputProps={{
                      style: { color: "white" },
                    }}
                    sx={{
                      "& label": { color: "white" },
                      "& label.Mui-focused": { color: "white" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "white" },
                        "&:hover fieldset": { borderColor: "white" },
                        "&.Mui-focused fieldset": { borderColor: "white" },
                      },
                      "& .MuiInput-underline:after": { borderBottomColor: "white" },
                    }}
                  />
                  <EmailLoginButton
                    style={{ padding: "4px" }}
                    doLoginFunction={props.doLoginFunction}
                    code={code}
                    email={email}
                  />
                </>
              )}
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  setShowEmailLogin(false);
                  setEmail("");
                  setCode("");
                  setSentRequestForCode(false);
                }}
                style={{ margin: "8px" }}
              >
                Tillbaka
              </Button>
            </>
          )}
        </Box>

        <Link
      onClick={() => {
        history("/faq");
      }}
      style={{
        color: "white",
        padding: "12px",
        paddingBottom: "20px",
        paddingTop: "20px",
        display: "flex",
        alignItems: "center",
        fontSize: "1.1rem",
        fontWeight: 500,
        transition: "opacity 0.3s",
      }}
      onMouseEnter={(e) => e.target.style.opacity = 0.8}
      onMouseLeave={(e) => e.target.style.opacity = 1}
      aria-label="Get help with login"
    >
      <HelpOutlineIcon style={{ marginRight: "8px" }} />
      <i>Vanliga frågor</i>
    </Link>
    <Link
  href="https://skuttli.org"
  target="_blank"
  rel="noopener noreferrer"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '0.8rem',
    marginTop: '24px',
    textDecoration: 'none',
  }}
>

  © {new Date().getFullYear()} Skuttli AB
</Link>
      </Grid>
    
  );
}

export default LoginView;