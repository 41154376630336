import React from "react";
import { useMediaQuery, useTheme, List, ListItem, ListItemText, Typography, Box } from "@mui/material";

function GenericMobileList({ items, columns, onItemClick }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!isMobile) return null;

  return (
    <List sx={{ width: "100%", bgcolor: "transparent", p: 0, mb: 2, mt: 2 }}>
      {items.map((item) => (
        <ListItem
          key={item.id}
          button
          onClick={() => onItemClick(item.id)}
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            mb: 2,
            boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
            p: 2,
          }}
        >
          <ListItemText
            primary={
              <Typography variant="h6" color="#D6409B" fontWeight="bold" gutterBottom>
                {item[columns[0].field]}
              </Typography>
            }
            secondary={
              <Box>
                {columns.slice(1).map((column) => (
                  <Typography key={column.field} variant="body2" color="text.secondary">
                    <strong>{column.headerName}:</strong> {item[column.field]}
                  </Typography>
                ))}
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default GenericMobileList;