import React from "react";
import GenericListView from './GenericListView';
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";

function RabbitsView() {
  const columns = [
    { field: 'name', headerName: 'Namn', flex: 1, minWidth: 120 },
    { field: 'breeder', headerName: 'Uppfödare', flex: 1, minWidth: 80 },
    { field: 'identificationNumber', headerName: 'RegNr', flex: 1, minWidth: 80 },
    { field: 'dateOfBirth', headerName: 'Födelsedatum', flex: 1, minWidth: 100 },
    { field: 'sex', headerName: 'Kön', flex: 1, minWidth: 80 },
  ];

  const transformData = (rabbits) => rabbits.map(rabbit => ({
    id: rabbit.id,
    name: rabbit.prefix ? `${rabbit.prefix} ${rabbit.name}` : rabbit.name,
    dateOfBirth: rabbit.dateOfBirth ? new Date(rabbit.dateOfBirth).toLocaleDateString("sv-SE") : '-',
    sex: rabbit.sex === 'F' ? 'Hona' : rabbit.sex === 'M' ? 'Hane' : '-',
    prefix: rabbit.prefix || '-',
    breeder: rabbit.breeder ? rabbit.breeder : '-',
    identificationNumber: rabbit.identificationNumber || '-',
  }));

  return (
    <GenericListView
      title="Kaniner"
      newItemButtonText="Ny kanin"
      newItemPath="/rabbitCrud"
      apiCall={() => DjurliAPI.getRabbits(ApplicationState.getToken())}
      columns={columns}
      searchFields={['name', 'prefix', 'breeder', 'identificationNumber', 'dateOfBirth', 'sex']}
      detailPath="/rabbit"
      transformData={transformData}
      onNewItemClick={() => ApplicationState.setRabbitToEdit(undefined)}
    />
  );
}

export default RabbitsView;