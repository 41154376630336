import React from "react";
import GenericListView from './GenericListView';
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import StarIcon from '@mui/icons-material/Star';

function BreedersView() {
  const columns = [
    { 
      field: 'owner', 
      headerName: 'Ägare', 
      flex: 1, 
      minWidth: 120,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params.row.prefix !== '-' && (
            <StarIcon color="primary"  style={{ marginRight: '8px'}} />
          )}
          {params.value}
        </div>
      )
    },
    { field: 'prefix', headerName: 'Prefix', flex: 1, minWidth: 120, renderCell: (params) => (
      params.value !== '-' ? <strong>{params.value}</strong> : params.value
    )},
    { field: 'city', headerName: 'Ort', flex: 1, minWidth: 120 },
  ];

  const transformData = (breeders) => breeders.map(breeder => ({
    id: breeder.id,
    prefix: breeder.prefix || '-',
    owner: breeder.owner
    //ToDo: Lös med en NavProp som skickar hela User-objektet 
    ? `${breeder.owner.firstName} ${breeder.owner.lastName}`.trim() 
    : breeder.ownerText || '-',
    city: breeder.city || '-',
  }));

  return (
    <GenericListView
      title="Uppfödare"
      newItemButtonText="Ny uppfödare"
      newItemPath="/breederCrud"
      apiCall={() => DjurliAPI.getBreeders(ApplicationState.getToken())}
      columns={columns}
      searchFields={['prefix', 'owner', 'location']}
      detailPath="/breeder"
      transformData={transformData}
      onNewItemClick={() => ApplicationState.setBreederToEdit(undefined)}
      canUserAddNewItems={ApplicationState.isAdmin()}
    />
  );
}

export default BreedersView;