import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import DjurliAPI from "../DjurliAPI";
// import DjurliAPI from "./DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Grid, Typography, Card, Divider, Checkbox } from "@mui/material";
import MembershipDto from "../Dtos/MembershipDto";
import ApplicationState from "../ApplicationState";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

function MembershipCrudView(props) {
  let history = useNavigate();

  const [title, setTitle] = useState("Nytt medlemskap");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [user, setUser] = useState("");
  const [approved, setApproved] = useState("");
  const [approvedAt, setApprovedDate] = useState("");
  const [price, setPrice] = useState(0);
  const [familyMembership, setFamilyMembership] = useState(false);

  const [users, setUsers] = useState([]);

  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [userError, setUserError] = useState("");
  const [approvedError, setApprovedError] = useState("");
  const [approvedAtError, setApprovedDateError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [familyMembershipError, setFamilyMembershipError] = useState("");

  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let us = await DjurliAPI.getUsers(ApplicationState.getToken());
      setUsers(us);
      let membershipToEdit = ApplicationState.getMembershipToEdit();
      if(membershipToEdit) {
        setTitle("Redigera medlemskap");
        setStartDate(membershipToEdit.startDate);
        setEndDate(membershipToEdit.endDate);
        setUser(membershipToEdit.user);
        setApproved(membershipToEdit.approved);
        setApprovedDate(membershipToEdit.approvedAt);
        setPrice(membershipToEdit.price);
        setFamilyMembership(membershipToEdit.familyMembership);
      }
    };
    load1();
  }, []);

  const validateControls = () => {
    let valid = true;
    if (!validateStartDate()) {
      valid = false;
    }
    if (!validateEndDate()) {
      valid = false;
    }
    if (!validateUser()) {
      valid = false;
    }
    if (!validateApproved()) {
      valid = false;
    }
    if (!validateApprovedDate()) {
      valid = false;
    }
    if (!validatePrice()) {
      valid = false;
    }
    if (!validateFamilyMembership()) {
      valid = false;
    }
    return valid;
  };

  function validateStartDate() {
    if (startDate.length < 1) {
      setStartDateError("Startdatum måste anges.");
      return false;
    } else {
      setStartDateError("");
      return true;
    }
  }

  function validateEndDate() {
    if (endDate.length < 1) {
      setEndDateError("Slutdatum måste anges.");
      return false;
    } else {
      setEndDateError("");
      return true;
    }
  }

  function validateUser() {
    if (user.length < 1) {
      setUserError("Användare måste anges.");
      return false;
    } else {
      setUserError("");
      return true;
    }
  }

  function validateApproved() {
    if (approved.length < 1) {
      setApprovedError("Godkänd måste anges.");
      return false;
    } else {
      setApprovedError("");
      return true;
    }
  }

  function validateApprovedDate() {
    if (approvedAt.length < 1) {
      setApprovedDateError("Godkännandedatum måste anges.");
      return false;
    } else {
      setApprovedDateError("");
      return true;
    }
  }

  function validatePrice() {
    if (price < 0) {
      setPriceError("Pris måste anges.");
      return false;
    } else {
      setPriceError("");
      return true;
    }
  }

  function validateFamilyMembership() {
    if (familyMembership.length < 1) {
      setFamilyMembershipError("Familjemedlemskap måste anges.");
      return false;
    } else {
      setFamilyMembershipError("");
      return true;
    }
  }



  return (
    <Card style={{ backgroundColor: "white", margin: "8px" }}>
      <Box p={2}>
        <Typography variant="h4">{title}</Typography>
      </Box>

      <Box p={1}>

      <TextField
          id="startDateTextField"
          type="date"
          InputLabelProps={{
            shrink: true, // Detta säkerställer att label inte hamnar i vägen för värdet när det inte är fokus på komponenten
          }}
          label="Startdatum"
          style={{ margin: "8px", backgroundColor: "white" }}
          variant="outlined"
          value={startDate}
          error={startDateError}
          helperText={startDateError}
          onChange={(event) => {
            setStartDate(event.target.value);
            validateStartDate();
          }}
        />
        <br />
        <TextField
          id="endDateTextField"
          type="date"
          InputLabelProps={{
            shrink: true, // Detta säkerställer att label inte hamnar i vägen för värdet när det inte är fokus på komponenten
          }}
          label="Slutdatum"
          style={{ margin: "8px", backgroundColor: "white" }}
          variant="outlined"
          value={endDate}
          error={endDateError}
          helperText={endDateError}
          onChange={(event) => {
            setEndDate(event.target.value);
            validateEndDate();
          }}
        />
        <br />
        <Autocomplete
          id="users"
          sx={{
            width: 300,
            margin: "8px",
            marginTop: "16px",
            marginBottom: "24px",
          }}
          options={users}
          value={user}
          autoHighlight
          onChange={(event, newValue) => {
            setUser(newValue);
          }}
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box>
                    <b>{option.firstName}</b>
                  </Box>
                  <Typography>
                    <i>
                      {option.lastName} - {option.id}
                    </i>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Användare"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
        
        <TextField
          id="priceTextField"
          type="number"
          label="Pris"
          style={{ margin: "8px", backgroundColor: "white" }}
          variant="outlined"
          value={price}
          error={priceError}
          helperText={priceError}
          onChange={(event) => {
            setPrice(event.target.value);
            validatePrice();
          }}
        />
        <br />
        
        <TextField
          id="approvedAtTextField"
          type="date"
          InputLabelProps={{
            shrink: true, // Detta säkerställer att label inte hamnar i vägen för värdet när det inte är fokus på komponenten
          }}
          label="Godkännandedatum"
          style={{ margin: "8px", backgroundColor: "white" }}
          variant="outlined"
          value={approvedAt}
          error={approvedAtError}
          helperText={approvedAtError}
          onChange={(event) => {
            setApprovedDate(event.target.value);
            validateApprovedDate();
          }}
        />
        <br />
        
        <FormControlLabel
        control={
          <Checkbox
            checked={approved}
            onChange={(event) => {
              setApproved(event.target.checked);
            }}
            name="approvedCheckbox"
            color="primary"
          />
        }
        label="Godkänd"
      />
      <br />
      
      <FormControlLabel
        control={
          <Checkbox
            checked={familyMembership}
            onChange={(event) => {
              setFamilyMembership(event.target.checked);
            }}
            name="familyMembershipCheckbox"
            color="primary"
          />
        }
        label="Familjemedlemskap"
      />
        <br />
      </Box>
      <Divider style={{ borderColor: "#d8d8d8" }} />
      <Box p={2}>
        <Button
          variant="contained"
          onClick={async () => {
            if (!validateControls()) {
              return;
            }

            let userIdToSave = undefined;
            
            if (user) {
              userIdToSave = user.id;
            }
            
            let dto = new MembershipDto(
              DjurliAPI.generateGuid(),
              undefined,
              startDate,
              endDate,
              userIdToSave,
              approved,
              approvedAt,
              price,
              familyMembership
            );
            let membershipToEdit = ApplicationState.getMembershipToEdit();
            if(membershipToEdit) {
              dto.id = membershipToEdit.id;
              dto.createdAt = membershipToEdit.createdAt;
              dto.createdBy = membershipToEdit.createdBy;
              await DjurliAPI.updateMembership(dto, ApplicationState.getToken());
            } else {
              await DjurliAPI.createMembership(dto, ApplicationState.getToken());
            }
            history("/memberships");
          }}
        >
          Spara
        </Button>
      </Box>
    </Card>
  );
}

export default MembershipCrudView;
