import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, Card, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: "Vad är Djurli?",
    answer: "Djurli är en applikation som hjälper idella föreningar och dess medlemmar att hantera information om sina husdjur. Djurli utvecklas av företaget Skuttli AB och säljs som en SaaS-tjänst."
  },
  {
    question: "Hur skapar jag ett konto?",
    answer: "Du kontaktar din förening som använder Djurli så kan de hjälpa dig att skapa ett konto."
  },
  {
    question: "Hur återställer jag mitt lösenord?",
    answer: "Ditt Djurli-konto har inget lösenord, du loggar istället in med hjälp av en säkerhetskod som skickas till din e-postadress eller via ditt Google-konto. Om du inte kan logga in, kontakta din förening för att få hjälp."
  },
  {
    question: "Jag behöver hjälp, vem ska jag kontakta?",
    answer: "Vänd dig i första hand till din förening som använder Djurli. De kan hjälpa dig med de flesta frågor och problem. Om de inte kan hjälpa dig skicka ett mail till support@djurli.com så försöker vi hjälpa dig så fort som möjligt."
  }
];

function FAQView() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box p={2} sx={{ width: "90%", maxWidth: 800, margin: '0 auto' }}>
      <Card elevation={3} sx={{ bgcolor: "#f9f4f9" }}>
        <Box
          sx={{
            p: isMobile ? 2 : 3,
            bgcolor: "#f0e6f0",
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{
              color: "#4A0E4E",
              fontWeight: "bold",
            }}
          >
            Vanliga frågor (FAQ)
          </Typography>
        </Box>

        <Box sx={{ p: isMobile ? 2 : 3, bgcolor: 'white' }}>
          {faqData.map((faq, index) => (
            <Accordion 
              key={index} 
              sx={{ 
                bgcolor: 'transparent', 
                boxShadow: 'none', 
                '&:before': { display: 'none' },
                '&:not(:last-child)': { borderBottom: 1, borderColor: 'divider' },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#4A0E4E" }} />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{ 
                  '& .MuiAccordionSummary-content': { margin: '10px 0' },
                }}
              >
                <Typography sx={{ fontWeight: 'bold', color: "#4A0E4E" }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: "#333" }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Card>
    </Box>
  );
}

export default FAQView;