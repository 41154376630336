import GenericLogView from './GenericLogView';
import DjurliAPI from '../DjurliAPI';

const fieldMappings = {
  birthDate: "Födelsedatum",
  breeder: "Uppfödare",
  breederId: "UppfödarId",
  comment: "Kommentar",
  fatherId: "FarId",
  motherId: "MorId",
  createdAt: "Skapad",
  babiesAtBirth: "Ungar vid födseln",
  babiesAfterThreeWeeks: "Ungar efter tre veckor",
};

const LitterLogView = () => (
  <GenericLogView
    getLogsFunction={DjurliAPI.getLogsForLitter.bind(DjurliAPI)}
    getObjectFunction={DjurliAPI.getLitter.bind(DjurliAPI)}
    fieldMappings={fieldMappings}
    objectName="kullen"
    objectNameField="name"
  />
);

export default LitterLogView;