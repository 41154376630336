import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import ApplicationState from "../ApplicationState";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";

const CLIENT_ID =
  "26267625079-meriaac295anfgpfgimga8dqt7dnkbr3.apps.googleusercontent.com";

export default function GoogleLoginButton(props) {
    let history = useNavigate();
  async function login(response) {
    try{
    let userInfoDecoded = jwtDecode(response.credential);
    if (
      userInfoDecoded &&
      userInfoDecoded.email &&
      userInfoDecoded.email_verified
    ) {
      await ApplicationState.initilizeState();
      let result = await ApplicationState.loginUser(
        userInfoDecoded.email,
        "Google",
        response.credential
      );
      if (result === "Login failed!") {
        alert("Inloggning misslyckades");
        return;
      }
      let user = await ApplicationState.getState().user;
      if (user === "No user found!") {
        alert("No user found!");
        return;
      }
      if (userInfoDecoded.picture && userInfoDecoded.picture.length > 0) {
        ApplicationState.setUserProfilePictureUrl(userInfoDecoded.picture);
      }
      await ApplicationState.loadDataForUser();
      props.doLoginFunction();
    }
    } catch (error) {
        history("/error", { state: { errorMessage: error.message } });
    }
  }

//   async function login() {
//     //await ApplicationState.loadConfig();
//     await ApplicationState.initilizeState();
//     let result = await ApplicationState.loginUser(
//       props.email,
//       "Email",
//       props.code
//     );
//     if (result === "Login failed!") {
//       alert("Inloggning misslyckades");
//       return;
//     }

//     let user = await ApplicationState.getState().user;
//     if (user === "No user found!") {
//       alert("No user found!");
//       return;
//     }

//     await ApplicationState.loadDataForUser();
//     props.doLoginFunction();
//   }

  function handleLoginFailure(response) {
    console.log(response);
    alert("Failed to log in");
  }

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <GoogleLogin
        clientId={CLIENT_ID}
        onSuccess={login}
        onError={handleLoginFailure}
        size="large"
        text="signin_with"
        theme="outline"
        logo_alignment="left"
        shape="square"
        width="212px"
        ux_mode="popup"
      ></GoogleLogin>
    </GoogleOAuthProvider>
  );
}
