import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography, Container, Paper } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const AccessDeniedView = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth={false} sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "calc(100vh - 64px)",
          my: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 2,
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <LockOutlinedIcon sx={{ fontSize: 48, color: "#d32f2f", mb: 2 }} />
          <Typography variant="h5" sx={{ color: "#d32f2f", fontWeight: "bold", mb: 2 }}>
            Åtkomst nekad
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
            Du har inte behörighet att komma åt denna sida. Kontakta din administratör om du tror att detta är ett fel.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/")}
            sx={{ mt: 2 }}
          >
            Gå till startsidan
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default AccessDeniedView;