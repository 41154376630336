import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography, 
  Box
} from '@mui/material';

const GenericTable = ({ 
  data, 
  columns, 
  onRowClick, 
  emptyMessage = "Ingen data tillgänglig.",
  headerColor = '#4A0E4E',
  headerTextColor = 'white',
  footerColor = '#efefef',
  footerTextColor = '#333333'
}) => {
  const getCellContent = (row, column) => {
    if (typeof column.render === 'function') {
      return column.render(row);
    }
    return row[column.id];
  };

  return (
    <TableContainer 
      component={Box} 
      sx={{ 
        borderRadius: '8px', 
        overflow: 'hidden',
        border: '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <Table sx={{ borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell 
                key={column.id} 
                sx={{ 
                  backgroundColor: headerColor, 
                  color: headerTextColor,
                  fontWeight: 'bold',
                  borderBottom: 'none',
                  '&:first-of-type': {
                    borderTopLeftRadius: '8px',
                  },
                  '&:last-child': {
                    borderTopRightRadius: '8px',
                  },
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <Typography>{emptyMessage}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            data.map((row, rowIndex) => (
              <TableRow 
                key={row.id || rowIndex} 
                hover 
                onClick={() => onRowClick && onRowClick(row)}
                sx={{ 
                  cursor: onRowClick ? 'pointer' : 'default',
                  '&:last-child td': {
                    borderBottom: 'none',
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell key={column.id} sx={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }}>
                    {getCellContent(row, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
          <TableRow>
            <TableCell 
              colSpan={columns.length} 
              align="right"
              sx={{
                backgroundColor: footerColor,
                color: footerTextColor,
                fontWeight: 'bold',
                borderBottom: 'none',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }}
            >
              Antal: {data.length}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenericTable;