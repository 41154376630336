import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenericObjectView from "./GenericObjectView";
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PetRegistrationDto from "../Dtos/PetRegistrationDto";
import { Typography, Box, CircularProgress, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import LoadingSpinner from "./LoadingSpinner";
import EditIcon from "@mui/icons-material/Edit";

const RatView = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [ratData, setRatData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadRatData = useCallback(async () => {
    setLoading(true);
    try {
      const rat = await DjurliAPI.getRat(id, ApplicationState.getToken());
      const registration = await DjurliAPI.getPetRegistrationByPetId(
        id,
        ApplicationState.getToken()
      );

      if (rat.motherId) {
        rat.mother = await DjurliAPI.getRat(
          rat.motherId,
          ApplicationState.getToken()
        );
      }
      if (rat.fatherId) {
        rat.father = await DjurliAPI.getRat(
          rat.fatherId,
          ApplicationState.getToken()
        );
      }
      rat.offsprings = await DjurliAPI.getOffspringsForPet(
        id,
        ApplicationState.getToken()
      );
      try {
        rat.familyTree = await DjurliAPI.getFamilyTreeForPet(
          id,
          ApplicationState.getToken()
        );
      } catch (error) {
        console.error("Kunde inte hämta släktträd:", error);
        rat.familyTree = { html: "Kunde inte ladda släktträd." };
      }
      if (rat.breederId) {
        rat.breeder = await DjurliAPI.getBreeder(
          rat.breederId,
          ApplicationState.getToken()
        );
      }
      if (rat.ownerUserId) {
        rat.owner = await DjurliAPI.getUser(
          rat.ownerUserId,
          ApplicationState.getToken()
        );
      }

      rat.registration = registration;
      setRatData(rat);
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadRatData();
  }, [loadRatData]);

  const deleteRat = async (id, token) => {
    await DjurliAPI.deleteRat(id, token);
    history("/rats");
  };

  const getTitleString = (rat) => {
    return rat ? rat.name : "Okänd råtta";
  };

  const getRegistrationStatus = (registration) => {
    console.log(registration);
    if (!registration) return "Ej registrerad";
    if (registration && !registration.approvedAt) return "REGISTRERING PÅGÅR";
    if (registration.approvedAt) return "REGISTRERAD";
    return "UNKNOWN STATUS";
  };
  
  const getGroupedData = (rat) => {
    if (!rat) return [];
    return [
      {
        title: "Grundläggande information",
        data: [
          { label: "Namn", value: rat.name },
          { label: "Kön", value: rat.sex === "F" ? "Hona" : rat.sex === "M" ? "Hane" : "?" },
          { label: "Födelsedatum", value: rat.dateOfBirth ? new Date(rat.dateOfBirth).toLocaleDateString() : undefined },
          { label: "Prefix", value: rat.prefix },
          { label: "RegNr", value: rat.identificationNumber },
          { label: "Registreringsstatus", value: getRegistrationStatus(rat.registration) },
        ]
      },
      {
        title: "Utseende",
        data: [
          { label: "Färg", value: rat.color },
          { label: "Teckning", value: rat.markings },
          { label: "Päls", value: rat.furType },
          { label: "Öron", value: rat.earType },
          { label: "Speciella kännetecken", value: rat.distinctiveFeatures },
        ]
      },
      {
        title: "Föräldrar",
        data: [
          { label: "Far", value: rat.father ? rat.father.name : undefined },
          { label: "Mor", value: rat.mother ? rat.mother.name : undefined },
        ]
      },
      {
        title: "Uppfödning och Ägande",
        data: [
          { label: "Uppfödare", value: rat.breeder ? rat.breeder.prefix : rat.breederText || undefined },
          { label: "Ägare", value: rat.owner ? `${rat.owner.firstName} ${rat.owner.lastName}` : rat.ownerText || undefined },
        ]
      },
      {
        title: "Övrig information",
        data: [
          { label: "Dödsdatum", value: rat.dateOfDeath ? new Date(rat.dateOfDeath).toLocaleDateString() : undefined },
          { label: "Dödsorsak", value: rat.causeOfDeath },
          { label: "Kommentar", value: rat.comment },
        ]
      }
    ];
  };

  const handleEditAction = () => {
    if (ratData) {
      ApplicationState.setRatToEdit(ratData);
      history("/ratCrud");
    } else {
      console.error("Försökte redigera en odefinierad råtta");
    }
  };

  const getAdditionalContentSections = useCallback(() => {
    if (!ratData) return [];
  
    const groupOffspringsByGeneration = (offsprings) => {
      return offsprings.reduce((acc, offspring) => {
        if (!acc[offspring.generation]) {
          acc[offspring.generation] = [];
        }
        acc[offspring.generation].push(offspring);
        return acc;
      }, {});
    };
  
    return [
      {
        title: "Släktträd",
        content: ratData.familyTree && ratData.familyTree.html ? (
          <div dangerouslySetInnerHTML={{ __html: ratData.familyTree.html }} />
        ) : (
          <Typography>Släktträd är inte tillgängligt.</Typography>
        )
      },
      {
        title: "Avkommor",
        content: ratData.offsprings && ratData.offsprings.length > 0 ? (
          Object.entries(groupOffspringsByGeneration(ratData.offsprings)).map(([generation, offsprings]) => (
            <Box key={generation}>
              <Typography variant="subtitle1" sx={{pt: 2}}>Generation {generation}</Typography>
              {offsprings.map((offspring, index) => (
                <Box sx={{pl: 2}} key={index}>
                  <Link 
                    to={`/rat/${offspring.id}`}
                    style={{ 
                      color: '#D6409B', 
                      textDecoration: 'none', 
                      '&:hover': { 
                        textDecoration: 'underline' 
                      } 
                    }}
                  >
                    {offspring.name}
                  </Link>
                </Box>
              ))}
            </Box>
          ))
        ) : (
          <Typography>Inga avkommor hittades.</Typography>
        )
      }
    ];
  }, [ratData]);

  const additionalMenuItems = [
    {
      label: "Logg",
      icon: AssignmentIcon,
      onClick: () => {
        history("/ratLogs/" + ratData.id);
      },
      condition: () => ApplicationState.isAdmin(),
    },
    {
      label: "Ladda ner PDF",
      icon: PictureAsPdfIcon,
      onClick: () => handleDownload(ratData.id),
    },
    //Dolt registrerings knapparna då dessa ska använda vid registreringsvyn
    {
      label: "Registrera med RegNr",
      icon: AddCircleOutlineIcon,
      onClick: async () => {
        try {
          let dto = new PetRegistrationDto(
            DjurliAPI.generateGuid(),
            ratData.id,
            undefined,
            undefined,
            true,
            "Rat"
          );
          await DjurliAPI.createPetRegistration(
            dto,
            ApplicationState.getToken()
          );
          await loadRatData();
        } catch (error) {
          history("/error", { state: { errorMessage: error.message } });
        }
      },
      condition: () => !ratData.registration && ApplicationState.isAdmin(),
    },
    // {
    //   label: "Registrera UTAN RegNr",
    //   icon: AddCircleOutlineIcon,
    //   onClick: async () => {
    //     try {
    //       let dto = new PetRegistrationDto(
    //         DjurliAPI.generateGuid(),
    //         ratData.id,
    //         undefined,
    //         undefined,
    //         new Date(),
    //         ApplicationState.getUser().id,
    //         false
    //       );
    //       await DjurliAPI.createPetRegistration(
    //         dto,
    //         ApplicationState.getToken()
    //       );
    //       await loadRatData();
    //     } catch (error) {
    //       history("/error", { state: { errorMessage: error.message } });
    //     }
    //   },
    //   condition: () => !ratData.registration,
    // },
    // {
    //   label: "Godkänn registrering",
    //   icon: CheckCircleOutlineIcon,
    //   onClick: async () => {
    //     try {
    //       await DjurliAPI.approvePetRegistration(
    //         ratData.registration.id,
    //         ApplicationState.getToken()
    //       );
    //       await loadRatData();
    //     } catch (error) {
    //       history("/error", { state: { errorMessage: error.message } });
    //     }
    //   },
    //   condition: () => ratData.registration && !ratData.registration.approved,
    // },
  ];

  if (loading) {
    return (
      <LoadingSpinner />
    );
  }

  if (!ratData) {
    return <Typography>Ingen råttdata tillgänglig.</Typography>;
  }

  const handleDownload = async (id) => {
    if (!id) {
      alert('Inget ID tillhandahållet');
      return;
    }
  
    try {
      // Fetch the blob using your API function
      const blob = await DjurliAPI.getRatPdf(id, ApplicationState.getToken());
  
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary link and click it to start the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `filled_form_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    }
  };
  
  const additionalSections = getAdditionalContentSections();

  return (
    <GenericObjectView
      deleteRedirectPath="rats"
      objectTypeText="råtta"
      object={ratData}
      deleteObject={deleteRat}
      getTitleString={getTitleString}
      getGroupedData={getGroupedData}
      additionalContentSections={additionalSections}
      apiToken={ApplicationState.getToken()}
      onMainAction={handleEditAction}
      mainActionIcon={<EditIcon />}
      mainActionText="Redigera"
      additionalMenuItems={additionalMenuItems}
    />
  );
};

export default RatView;
