import React, { useEffect, useState } from "react";
import DjurliAPI from "../DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  Divider,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BreederDto from "../Dtos/BreederDto";
import ApplicationState from "../ApplicationState";
import CustomTextField from "./CustomTextField";
import GenericAutocomplete from "./GenericAutocomplete";

function BreederCrudView(props) {
  let history = useNavigate();

  const [title, setTitle] = useState("Ny Uppfödare");

  const [prefix, setPrefix] = useState("");
  const [city, setCity] = useState("");
  const [owners, setOwners] = useState([]);
  const [owner, setOwner] = useState(null);
  const [ownerText, setOwnerText] = useState("");
  const [ownerHasAccount, setOwnerHasAccount] = useState(false);
  const [description, setDescription] = useState("");

  const [prefixError, setPrefixError] = useState("");
  const [ownerError, setOwnerError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const load1 = async () => {
      try {
        let users = await DjurliAPI.getUsers(ApplicationState.getToken());
        users = users.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setOwners(users);
        let breederToEdit = ApplicationState.getBreederToEdit();
        if (breederToEdit) {
          setTitle("Redigera Uppfödare");
          setPrefix(breederToEdit.prefix);
          setCity(breederToEdit.city);
          setDescription(breederToEdit.description);
          setOwnerText(breederToEdit.ownerText);
          if (breederToEdit.ownerUserId) {
            let o = await DjurliAPI.getUser(
              breederToEdit.ownerUserId,
              ApplicationState.getToken()
            );
            setOwner(o);
            setOwnerHasAccount(true);
          }
        }
      } catch (error) {
        history("/error", { state: { errorMessage: error.message } });
      }
    };
    load1();
  }, []);

  const validateControls = () => {
    let valid = true;
    if (!validatePrefix()) {
      valid = false;
    }
    if (!validateOwner()) {
      valid = false;
    }
    if (!validateDescription()) {
      valid = false;
    }
    return valid;
  };

  function validatePrefix() {
    if(!prefix && !ownerText && !owner){
      setPrefixError("Prefix eller Ägare måste anges.");
      return
    }
    if (prefix && prefix.length < 3 && prefix.length > 0) {
      setPrefixError("Prefixet måste vara minst 3 tecken långt om det anges.");
      return false;
    } else {
      setPrefixError("");
      return true;
    }
  }

  function validateOwner() {
    setOwnerError("");
    return true;
  }

  function validateDescription() {
    if (description && description.length < 3 && description.length > 0) {
      setDescriptionError("Beskrivningen måste vara minst 3 tecken lång om den anges.");
      return false;
    } else {
      setDescriptionError("");
      return true
    }
  }

  return (
    <Box p={2} sx={{ maxWidth: 600 }}>
      <Card elevation={3} style={{ backgroundColor: "#f9f4f9" }}>
        <Box p={2}>
          <Typography
            variant={"h5"}
            component="h1"
            sx={{
              color: "#4A0E4E",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            p: isMobile ? 2 : 3,
            bgcolor: "white",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        >
          <CustomTextField
            id="prefixTextField"
            label="Prefix"
            value={prefix}
            error={Boolean(prefixError)}
            helperText={prefixError}
            onChange={(event) => {
              setPrefix(event.target.value);
            }}
            onBlur={validatePrefix}
          />
          <CustomTextField
            id="cityTextField"
            label="Ort"
            value={city}
            onChange={(event) => {
              setCity(event.target.value);
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ownerHasAccount}
                onChange={(event) => {
                  setOwnerHasAccount(event.target.checked);
                }}
                style={{ marginLeft: "8px" }}
              />
            }
            label="Ägare har konto"
          />

          {ownerHasAccount ? (
            <GenericAutocomplete
            id="owners"
            options={owners}
            value={owner}
            onChange={(event, newValue) => {
              setOwner(newValue);
              setOwnerText(undefined);
            }}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            label="Ägare"
            primaryOptionProperty={(option) => `${option.firstName} ${option.lastName}`}
            secondaryOptionProperty="email"
          />
          ) : (
            <CustomTextField
              id="ownerTextField"
              label="Ägare"
              value={ownerText}
              error={Boolean(ownerError)}
              helperText={ownerError}
              onChange={(event) => {
                setOwnerText(event.target.value);
                setOwner(undefined);
              }}
              onBlur={validateOwner}
            />
          )}
          <CustomTextField
          id="descriptionTextField"
          label="Beskrivning"
          value={description}
          error={Boolean(descriptionError)}
          helperText={descriptionError}
          onChange={(event) => setDescription(event.target.value)}
          onBlur={validateDescription}
          multiline
        />

        </Box>
        <Divider style={{ borderColor: "#d8d8d8" }} />
        <Box p={2}>
          <Button
            variant="contained"
            onClick={async () => {
              try {
                if (!validateControls()) {
                  return;
                }

                let ownerIdToSave = undefined;
                let ownerTextToSave = ownerText;
                if (!ownerHasAccount) {
                  setOwner(undefined);
                }
                if (owner) {
                  ownerIdToSave = owner.id;
                  ownerTextToSave = undefined;
                }

                let dto = new BreederDto(
                  DjurliAPI.generateGuid(),
                  prefix,
                  ownerIdToSave,
                  ownerTextToSave,
                  city
                );
                let breederToEdit = ApplicationState.getBreederToEdit();
                if (breederToEdit) {
                  dto.id = breederToEdit.id;
                  dto.createdAt = breederToEdit.createdAt;
                  dto.createdBy = breederToEdit.createdBy;
                  await DjurliAPI.updateBreeder(dto, ApplicationState.getToken());
                } else {
                  await DjurliAPI.createBreeder(dto, ApplicationState.getToken());
                }
                history("/breeder/" + dto.id);
              } catch (error) {
                history("/error", { state: { errorMessage: error.message } });
              }
            }}
          >
            Spara
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default BreederCrudView;