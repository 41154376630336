import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import GenericObjectView from "./GenericObjectView";
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, Link } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";

const PetRegistrationView = () => {
  const history = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [registrationData, setRegistrationData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadRegistrationData = useCallback(async () => {
    setLoading(true);
    try {
      const registration = await DjurliAPI.getPetRegistration(id, ApplicationState.getToken());
      const pet = await DjurliAPI.getPet(registration.petId, ApplicationState.getToken());
      const creator = await DjurliAPI.getUser(registration.createdBy, ApplicationState.getToken());
      
      let approver = null;
      if (registration.approvedBy) {
        approver = await DjurliAPI.getUser(registration.approvedBy, ApplicationState.getToken());
      }

      setRegistrationData({ ...registration, pet, creator, approver });
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  }, [id, history]);

  useEffect(() => {
    loadRegistrationData();
  }, [loadRegistrationData]);

  const deleteRegistration = async (id, token) => {
    try {
      await DjurliAPI.deletePetRegistration(id, token);
      history("/petRegistrations");
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    }
  };

  const getTitleString = (registration) => {
    return registration ? `Registrering av ${registration.pet.name}` : "Okänd registrering";
  };

  const getGroupedData = (registration) => {
    if (!registration) return [];
    return [
      {
        title: "Grundläggande information",
        data: [
          { label: "Skapad av", value: `${registration.creator.firstName} ${registration.creator.lastName} ${new Date(registration.createdAt).toLocaleDateString("sv-SE")}` },
          { label: "Status", value: registration.approvedAt ? `Godkänd av ${registration.approver.firstName} ${registration.approver.lastName} ${new Date(registration.approvedAt).toLocaleDateString("sv-SE")}` : "Väntar godkännande" },
          { label: "Generera RegNr vid godkännande", value: registration.generateIdentificationNumber ? "Ja" : "Nej" },
          { label: "RegNr", value: registration.pet.identificationNumber || "Ej tilldelat" },
          { label: "Prefix", value: registration.pet.prefix },
          { label: "Namn", value: registration.pet.name },
          { label: "Födelsedatum", value: new Date(registration.pet.dateOfBirth).toLocaleDateString("sv-SE") },
          { label: "Färg", value: registration.pet.color },
          // { label: "Päls", value: registration.pet.furType },
          // { label: "Öron", value: registration.pet.earType },
          { label: "Teckning", value: registration.pet.markings },
          { label: "Kommentar", value: registration.pet.comment },
        ]
      },
    ];
  };

  const renderAdditionalContent = (registration) => {
    if (!registrationData) return null;
    return (
      <Typography variant="body1">
        <Link to={`/rat/${registrationData.rat.id}`}>Läs mer om råttan</Link>
      </Typography>
    );
  };

  const handleApprove = async () => {
    try {
      await DjurliAPI.approvePetRegistration(registrationData.id, ApplicationState.getToken());
      
      // Get the returnPage from URL parameters
      const searchParams = new URLSearchParams(location.search);
      const returnPath = searchParams.get('returnPath');
      console.log("returnPath", returnPath);
      if (returnPath) {
        // Redirect to the specified return page
        history(returnPath);
      } else {
        // If no returnPage is specified, reload the current page
        await loadRegistrationData();
      }
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    }
  };

  const handleRevokeApproval = async () => {
    try {
      await DjurliAPI.revokeApprovalForPetRegistration(registrationData.id, ApplicationState.getToken());
      await loadRegistrationData();
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    }
  };

  const getMainActionProps = (registration) => {
    if (!registration) return {};

    if (registration.approvedAt) {
      return {
        onMainAction: handleRevokeApproval,
        mainActionText: "Ångra godkännande",
        mainActionIcon: <CancelIcon />,
      };
    } else {
      return {
        onMainAction: handleApprove,
        mainActionText: "Godkänn",
        mainActionIcon: <CheckCircleOutlineIcon />,
      };
    }
  };

  const additionalMenuItems = [
    {
      label: "Logg",
      icon: AssignmentIcon,
      onClick: () => {
        history("/petRegistrationLogs/" + registrationData.id);
      },
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!registrationData) {
    return <Typography>Ingen registreringsdata tillgänglig.</Typography>;
  }

  return (
    <GenericObjectView
      deleteRedirectPath="petRegistrations"
      objectTypeText="registrering"
      object={registrationData}
      deleteObject={deleteRegistration}
      getTitleString={getTitleString}
      getGroupedData={getGroupedData}
      //renderAdditionalContent={renderAdditionalContent}
      apiToken={ApplicationState.getToken()}
      additionalMenuItems={additionalMenuItems}
      {...getMainActionProps(registrationData)}
    />
  );
};

export default PetRegistrationView;