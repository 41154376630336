import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  useMediaQuery, 
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PetsIcon from '@mui/icons-material/Pets';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import ScienceIcon from '@mui/icons-material/Science';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import HelpIcon from '@mui/icons-material/Help';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ApplicationState from '../ApplicationState';
import DjurliAPI from '../DjurliAPI';

const HomeView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const user = ApplicationState.getUser();
  const [organizationData, setOrganizationData] = useState({ name: '', logoUrl: '', supportEmail: '', petType: '' });

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const orgData = await DjurliAPI.getOrganization(ApplicationState.getToken());
        setOrganizationData(orgData);
      } catch (error) {
        console.error("Failed to fetch organization data:", error);
      }
    };

    fetchOrganizationData();
  }, []);

  const getPetTitle = () => {
    const petTypes = {
      "Rat": "Råttor",
      "Rabbit": "Kaniner",
      // Add more pet types as needed
    };
    return petTypes[organizationData.petType] || "?";
  };

  const getPetRoute = () => {
    const petRoutes = {
      "Rat": "/rats",
      "Rabbit": "/rabbits",
      // Add more routes as needed
    };
    return petRoutes[organizationData.petType] || "/pets";
  };

  const navigationCards = [
    { title: getPetTitle(), icon: PetsIcon, route: getPetRoute(), color: '#EB71A9' },
    { title: 'Kullar', icon: EmojiNatureIcon, route: '/litters', color: '#EB71A9' },
    { title: 'Provparning', icon: ScienceIcon, route: '/testMating', color: '#EB71A9' },
    { title: 'Uppfödare', icon: NaturePeopleIcon, route: '/breeders', color: '#EB71A9' },
    { title: 'FAQ', icon: HelpIcon, route: '/faq', color: '#EB71A9' },
  ];

  const lastCard = ApplicationState.isAdmin()
    ? { title: 'Att göra', icon: ListAltIcon, route: '/admin', color: '#EB71A9' }
    : { title: 'Kontakta support', icon: SupportAgentIcon, action: () => window.location.href = `mailto:${organizationData.supportEmail}`, color: '#EB71A9' };

  navigationCards.push(lastCard);

  return (
    <Box p={2} sx={{ width: "90%", maxWidth: '900px', margin: '0 auto' }}>
      <Card elevation={3} sx={{ bgcolor: '#f9f4f9', mb: 4 }}>
        <Box 
          sx={{ 
            p: isMobile ? 2 : 3, 
            bgcolor: '#f0e6f0',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {organizationData.logoUrl && (
            <img 
              src={organizationData.logoUrl} 
              alt="Organization Logo" 
              style={{ maxHeight: '100px', maxWidth: '100%', marginBottom: '1rem' }} 
            />
          )}
          <Typography variant="h4" sx={{ color: '#4A0E4E', fontWeight: 'bold' }}>
            Välkommen till {organizationData.name || 'Skuttli'}!
          </Typography>
        </Box>

        <CardContent>
          <Typography variant="h5" component="h2" sx={{ color: '#4A0E4E', mb: 3, textAlign: 'center' }}>
            Hej {user.firstName}! Vad vill du göra idag?
          </Typography>
          
          <Grid container spacing={3} justifyContent="center">
            {navigationCards.map((card, index) => (
              <Grid item xs={6} sm={4} md={4} key={index}>
                <Card 
                  sx={{ 
                    bgcolor: '#fff', 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={card.action || (() => navigate(card.route))}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                    <card.icon sx={{ fontSize: 60, color: card.color, mb: 2 }} />
                    <Typography variant="h6" sx={{ color: '#4A0E4E', fontWeight: 'bold' }}>{card.title}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HomeView;