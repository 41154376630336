import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
  Box, Button, Typography, Card, useMediaQuery, useTheme, 
  Menu, MenuItem, IconButton, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from "@mui/icons-material/Delete";
import ApplicationState from "../ApplicationState";

const GenericObjectView = ({ 
  deleteRedirectPath,
  objectTypeText,
  object,
  deleteObject,
  getTitleString,
  getGroupedData,
  additionalContentSections,
  apiToken,
  onMainAction,
  mainActionText = "Redigera",
  mainActionIcon,
  additionalMenuItems = [],
}) => {
  const history = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteObject(object.id, apiToken);
      history("/" + deleteRedirectPath);
    } catch (error) {
      history("/error", { state: { errorMessage: error.message } });
    }
    handleConfirmDialogClose();
    handleMenuClose();
  };

  const renderValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return (
        <Typography 
          variant="body1" 
          sx={{ 
            fontStyle: 'italic', 
            color: theme.palette.text.secondary,
            fontSize: '1rem'
          }}
        >
          Uppgift saknas
        </Typography>
      );
    }
    return (
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
        {value}
      </Typography>
    );
  };

  return (
    <Box p={2} sx={{ width: "90%" }}>
      <Card elevation={3} sx={{ bgcolor: '#f9f4f9' }}>
        <Box 
          sx={{ 
            p: isMobile ? 2 : 3, 
            bgcolor: '#f0e6f0',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
          }}
        >
          <Box 
            display="flex" 
            justifyContent="space-between" 
            alignItems="center"
          >
            <Typography 
              variant={"h5"} 
              component="h1" 
              sx={{ 
                color: '#4A0E4E', 
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: isMobile ? 'calc(100% - 50%)' : 'calc(100% - 100px)'
              }}
            >
              {getTitleString(object)}
            </Typography>
            <Box>
              {!isMobile && ApplicationState.isAdmin() && (
                <Button
                  variant="contained"
                  startIcon={mainActionIcon}
                  onClick={onMainAction}
                  sx={{
                    bgcolor: '#D6409B',
                    color: 'white',
                    mr: 1,
                    '&:hover': {
                      bgcolor: '#B02E7C',
                    }
                  }}
                >
                  {mainActionText}
                </Button>
              )}
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{ p: isMobile ? 2 : 3, bgcolor: '#f0e6f0' }}>
          {getGroupedData(object).map((group, groupIndex) => (
            <Box key={groupIndex} sx={{ mb: 3, bgcolor: 'white', p: 2, borderRadius: '8px' }}>
              <Typography variant="h6" component="h2" sx={{ color: '#4A0E4E', mb: 2 }}>
                {group.title}
              </Typography>
              <Box sx={{ ml: 2 }}>
                {group.data.map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ color: '#666', mb: 0.5 }}>
                      {item.label}
                    </Typography>
                    {renderValue(item.value)}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}

        {Array.isArray(additionalContentSections) && additionalContentSections.map((section, index) => (
          <Box key={index} sx={{ bgcolor: 'white', p: 2, borderRadius: '8px', mt: 3 }}>
            <Typography variant="h6" component="h2" sx={{ color: '#4A0E4E', mb: 2 }}>
              {section.title}
            </Typography>
            {section.content}
          </Box>
        ))}
        </Box>
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {isMobile && ApplicationState.isAdmin() && (
          <MenuItem onClick={onMainAction}>
            {mainActionIcon && React.cloneElement(mainActionIcon, { sx: { mr: 1 } })}
            {mainActionText}
          </MenuItem>
        )}
        {additionalMenuItems.map((item, index) => (
          (!item.condition || item.condition(object)) && (
            <MenuItem key={index} onClick={() => {
              item.onClick(object);
              handleMenuClose();
            }}>
              {item.icon && <item.icon sx={{ mr: 1 }} />}
              {item.label}
            </MenuItem>
          )
        ))}
        {ApplicationState.isAdmin() && (
          <MenuItem onClick={handleDeleteAction} sx={{color: "#DC143C"}}>
          <DeleteIcon sx={{ mr: 1, color: "#DC143C" }} />
          Ta bort
        </MenuItem>
        )}
      </Menu>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Bekräfta borttagning</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Är du säker på att du vill ta bort denna {objectTypeText}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} sx={{color: "#000"}}>
            Avbryt
          </Button>
          <Button onClick={confirmDelete} sx={{color: "#DC143C"}} autoFocus>
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GenericObjectView;