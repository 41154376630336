class RatHelper {
    static getAppearance(rat) {
        if(!rat.markings || rat.markings === "") {
            console.log(rat);
            return rat.color + " " + rat.furType + " " + rat.earType;
        }
        return rat.color + " " + rat.markings + " " + rat.furType + " " + rat.earType;
    }
  }
  
  export default RatHelper;