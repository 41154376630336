import GenericLogView from './GenericLogView';
import DjurliAPI from '../DjurliAPI';

const fieldMappings = {
  active: "Aktiv",
  admin: "Admin",
  createdAt: "Skapad",
  email: "E-post",
  firstName: "Förnamn",
  lastName: "Efternamn",
  deleted: "Raderad",
};

const RatLogView = () => (
  <GenericLogView
    getLogsFunction={DjurliAPI.getLogsForUser.bind(DjurliAPI)}
    getObjectFunction={DjurliAPI.getUser.bind(DjurliAPI)}
    fieldMappings={fieldMappings}
    objectName="användaren"
    objectNameField="email"
  />
);

export default RatLogView;