import GenericLogView from './GenericLogView';
import DjurliAPI from '../DjurliAPI';

const fieldMappings = {
  active: "Aktiv",
  approved: "Godkänd",
  approvedByUserId: "Godkänd av AnvändarId",
  admin: "Admin",
  createdAt: "Skapad",
  createdBy: "Skapad av AnvändarId",
  generateIdentificationNumber: "Generera med RegNr",
  ratId: "RåtId",
};

const PetRegistrationLogView = () => (
  <GenericLogView
    getLogsFunction={DjurliAPI.getLogsForPetRegistration.bind(DjurliAPI)}
    getObjectFunction={DjurliAPI.getPetRegistration.bind(DjurliAPI)}
    fieldMappings={fieldMappings}
    objectName="registrering"
    objectNameField="id"
  />
);

export default PetRegistrationLogView;