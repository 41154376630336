import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const GenericSelect = ({
  id,
  label,
  value,
  onChange,
  onBlur, 
  options,
  error,
  helperText,
  style = {},
  maxWidth
}) => {
  const defaultStyle = { margin: '8px',  marginLeft: '0px', minWidth: 300 };
  const combinedStyle = { ...defaultStyle, ...style };
  
  if (maxWidth) {
    combinedStyle.maxWidth = maxWidth;
  }

  return (
    <FormControl
      error={Boolean(error)}
      variant="outlined"
      style={combinedStyle}
      fullWidth
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
      >
        <MenuItem value="">
          <em>Välj {label.toLowerCase()}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default GenericSelect;