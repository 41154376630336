export default class LitterDto {
  constructor(id, fatherId, motherId, babiesAtBirth, babiesAfterThreeWeeks, breederId, comment, birthDate, sentForApprovalAt, sentForApprovalBy, approvedAt, approvedBy, petType) {
      this.id = id;
      this.motherId = motherId;
      this.fatherId = fatherId;
      this.babiesAtBirth = babiesAtBirth;
      this.babiesAfterThreeWeeks = babiesAfterThreeWeeks;
      this.breederId = breederId;
      this.comment = comment;
      this.birthDate = birthDate;
      this.sentForApprovalAt = sentForApprovalAt;
      this.sentForApprovalBy = sentForApprovalBy;
      this.approvedAt = approvedAt;
      this.approvedBy = approvedBy;
      this.petType = petType;
  }
}