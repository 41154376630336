import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Card,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import GenericDataGrid from "./GenericDataGrid";
import GenericMobileList from "./GenericMobileList";
import { LoadingIndicator, ErrorMessage, NoItemsMessage } from './UtilityComponents';

function GenericListView({
  title,
  newItemButtonText,
  newItemPath,
  apiCall,
  columns,
  searchFields,
  detailPath,
  transformData,
  onNewItemClick,
  returnPath,
  canUserAddNewItems = true,
}) {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        let data = await apiCall();
        if (transformData) {
          data = transformData(data);
        }
        setItems(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [apiCall, transformData]);

  const filteredItems = items.filter((item) =>
    searchFields.some((field) => {
      // Convert the field value to a string for searching, regardless of its original type
      const fieldValue = item[field] !== null && item[field] !== undefined ? item[field].toString().toLowerCase() : "";
      return fieldValue.includes(searchTerm.toLowerCase());
    })
  );

  return (
      <Box p={2} sx={{width: "90%"}}>
        <Card elevation={3} style={{ backgroundColor: "#f0e6f0", margin: "8px", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" sx={{ 
                color: '#4A0E4E', 
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginBottom: "16px"
              }}>
              {title}
            </Typography>
            <Box mb={2}>
            {newItemButtonText && canUserAddNewItems && (
              <Button
                variant="contained"
                sx={{ color: "#fff", backgroundColor: "#D6409B" }}
                onClick={() => { 
                  if(onNewItemClick){
                    onNewItemClick();
                  }
                  navigate(newItemPath);
                }
                }
              >
                {newItemButtonText}
              </Button>
            )}
            </Box>
          </Grid>
          <Box mb={2}>
            <TextField
              fullWidth
              label={`Sök ${title.toLowerCase()}`}
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ backgroundColor: "#fff" }}
            />
          </Box>
          {loading ? (
            <LoadingIndicator />
          ) : error ? (
            <ErrorMessage error={error} />
          ) : (
            <Box sx={{ width: "100%" }}>
              <GenericDataGrid
                items={filteredItems}
                columns={columns}
                onRowClick={(id) => navigate(`${detailPath}/${id}?returnPath=${returnPath}`)}
              />
              <GenericMobileList
                items={filteredItems}
                columns={columns}
                onItemClick={(id) => navigate(`${detailPath}/${id}?returnPath=${returnPath}`)}
              />
            </Box>
          )}
          {!loading && !error && filteredItems.length === 0 && (
            <NoItemsMessage title={title} />
          )}
        </Card>
      </Box>
    
  );
}

export default GenericListView;