import React from "react";
import ApplicationState from "../ApplicationState";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

function EmailLoginButton(props) {
  let history = useNavigate();
  async function login() {
    //await ApplicationState.loadConfig();
    await ApplicationState.initilizeState();
    let result = await ApplicationState.loginUser(
      props.email,
      "Email",
      props.code
    );
    if(result === "Login failed!")  {
        alert("Inloggning misslyckades");
        return;
    }
    
    let user = await ApplicationState.getState().user;
    if (user === "No user found!") {
      alert("No user found!");
      return;
    }

    await ApplicationState.loadDataForUser();
    props.doLoginFunction();
  }

  function handleLoginFailure(response) {
    console.log(response);
    alert("Failed to log in");
  }

  return (
    <Box p={1}>
      <Button
        variant="outlined"
        color="secondary"
        type="button"
        onClick={() => login()}
      >
        Logga in
      </Button>
    </Box>
  );
}

export default EmailLoginButton;
