export default class UserDto {
    constructor(id, email, firstName, lastName, active, admin, city) {
      this.id = id;
      this.email = email;
      this.firstName = firstName;
      this.lastName = lastName;
      this.active = active;
      this.admin = admin;
      this.city = city;
    }
  }  