import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import DjurliAPI from "../DjurliAPI";
// import DjurliAPI from "./DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Form, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Grid,
  Typography,
  Card,
  Divider,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import UserDto from "../Dtos/UserDto";
import ApplicationState from "../ApplicationState";
import { CheckBox } from "@mui/icons-material";
import CustomTextField from "./CustomTextField";
import GenericCheckbox from "./GenericCheckbox";

function UserCrudView(props) {
  let history = useNavigate();

  const [title, setTitle] = useState("Ny Användare");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [city, setCity] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cityError, setCityError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let userToEdit = ApplicationState.getUserToEdit();
      if (userToEdit) {
        setTitle("Redigera Användare");
        setFirstName(userToEdit.firstName);
        setLastName(userToEdit.lastName);
        setEmail(userToEdit.email);
        setCity(userToEdit.city);
        setActive(userToEdit.active);
        setAdmin(userToEdit.admin);
      }
    };
    load1();
  }, []);

  const validateControls = () => {
    let valid = true;
    if (!validateFirstName()) {
      valid = false;
    }
    if (!validateLastName()) {
      valid = false;
    }
    if (!validateEmail()) {
      valid = false;
    }
    if (!validateCity()) {
      valid = false;
    }
    return valid;
  };

  function validateFirstName() {
    if (firstName.length < 3) {
      setFirstNameError("Förnamnet måste vara minst 3 tecken långt.");
      return false;
    } else {
      setFirstNameError("");
      return true;
    }
  }

  function validateLastName() {
    if (lastName.length < 3) {
      setLastNameError("Efternamnet måste vara minst 3 tecken långt.");
      return false;
    } else {
      setLastNameError("");
      return true;
    }
  }

  function validateEmail() {
    if (email.length < 3) {
      setEmailError("Email måste vara minst 3 tecken långt.");
      return false;
    }
    if (!email.includes("@")) {
      setEmailError("Email måste innehålla @.");
      return false;
    }
    setEmailError("");
    return true;
  }

  function validateCity() {
    if (city.length < 2) {
      setCityError("Staden måste vara minst 2 tecken långt.");
      return false;
    } else {
      setCityError("");
      return true;
    }
  }

  return (
    <Box p={2} sx={{ maxWidth: 600 }}>
      <Card elevation={3} style={{ backgroundColor: "#f9f4f9" }}>
        <Box p={2}>
          <Typography
            variant={"h5"}
            component="h1"
            sx={{
              color: "#4A0E4E",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            p: isMobile ? 2 : 3,
            bgcolor: "white",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        >
          <CustomTextField
            id="firstNameTextField"
            label="Förnamn"
            value={firstName}
            error={Boolean(firstNameError)}
            helperText={firstNameError}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
            onBlur={validateFirstName}
          />
          <CustomTextField
            id="lastNameTextField"
            label="Efternamn"
            value={lastName}
            error={Boolean(lastNameError)}
            helperText={lastNameError}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
            onBlur={validateLastName}
          />
          <CustomTextField
            id="emailTextField"
            label="Email"
            value={email}
            error={Boolean(emailError)}
            helperText={emailError}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onBlur={validateEmail}
          />

          <CustomTextField
            id="cityTextField"
            label="Ort"
            value={city}
            error={Boolean(cityError)}
            helperText={cityError}
            onChange={(event) => {
              setCity(event.target.value);
            }}
            onBlur={validateCity}
          />

          <GenericCheckbox
            label="Aktiv"
            checked={active}
            onChange={setActive}
          />
          <br />
          <GenericCheckbox 
            label="Admin"
            checked={admin}
            onChange={setAdmin}
          />

        </Box>
        <Divider style={{ borderColor: "#d8d8d8" }} />
        <Box p={2}>
          <Button
            variant="contained"
            onClick={async () => {
              try {
                if (!validateControls()) {
                  return;
                }

                let dto = new UserDto(
                  DjurliAPI.generateGuid(),
                  email,
                  firstName,
                  lastName,
                  active,
                  admin,
                  city
                );
                let userToEdit = ApplicationState.getUserToEdit();
                if (userToEdit) {
                  dto.id = userToEdit.id;
                  dto.createdAt = userToEdit.createdAt;
                  dto.createdBy = userToEdit.createdBy;
                  await DjurliAPI.updateUser(dto, ApplicationState.getToken());
                } else {
                  await DjurliAPI.createUser(dto, ApplicationState.getToken());
                }
                history("/users");
              } catch (error) {
                history("/error", { state: { errorMessage: error.message } });
              }
            }}
          >
            Spara
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default UserCrudView;
