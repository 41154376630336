import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DjurliAPI from "../DjurliAPI";
// import DjurliAPI from "./DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Padding } from "@mui/icons-material";
import ApplicationState from "../ApplicationState";

function MembershipView(props) {
  let history = useNavigate();
  let params = useParams();

  const [membership, setMembership] = useState([]);

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let r = await DjurliAPI.getMembership(params.id);
      setMembership(r);
    };
    load1();
  }, []);
  return (
    <Box p={2}>
      {/* <Button
        variant="contained"
        onClick={async () => {
          history("/membershipCrud");
        }}
      >
        Lägg till
      </Button> */}
      <Card
        style={{ backgroundColor: "white", margin: "8px", padding: "16px" }}
      >
        

        <Typography gutterBottom variant="h4" component="div">
          {membership.startDate} {membership.endDate}
        </Typography>

        <Divider style={{ borderColor: "#d8d8d8" }} />
        

        <Typography variant="h5" component="div" style={{paddingTop: "16px", paddingBottom: "16px"}}>
          Grundläggande information
        </Typography>

        <Typography variant="subtitle1">
          Period: <b>{membership.startDate} {" "} {membership.endDate}</b>
        </Typography>
        <Typography variant="subtitle1">
          Skapad: <b>{membership.createdAt}</b>
        </Typography>
        <Typography variant="subtitle1">
          Godkänt: <b>{membership.approved ? "Ja" : "Nej"}</b>
        </Typography>
        <Typography variant="subtitle1">
          Familjemedlemskap: <b>{membership.familyMembership ? "Ja" : "Nej"}</b>
        </Typography>
        <Typography variant="subtitle1">
          Godkännandedatum: <b>{membership.approvedAt}</b>
        </Typography>
        <Typography variant="subtitle1">
          Användare: <b>{membership.userId}</b>
        </Typography>

        <br />
        <br />

        <Divider style={{ borderColor: "#d8d8d8" }} />

        <Box style={{ paddingTop: "24px", marginBottom: "8px" }}>
          <Button
            style={{ color: "darkred", marginRight: "8px" }}
            variant="outlined"
            onClick={async () => {
              await DjurliAPI.deleteMembership(membership.id, ApplicationState.getToken());
              history("/memberships");
            }}
            endIcon={<DeleteIcon />}
          >
            <Box style={{ paddingTop: "4px" }}>Ta bort</Box>
          </Button>
          <Button
            style={{ color: "#9C27B0", marginRight: "8px" }}
            variant="outlined"
            onClick={async () => {
              ApplicationState.setMembershipToEdit(membership);
              console.log("Membership", membership);
              history("/membershipCrud");
            }}
            endIcon={<EditIcon />}
          >
            <Box style={{ paddingTop: "4px" }}>Redigera</Box>
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default MembershipView;
