import React, { useState } from "react";
import GenericListView from './GenericListView';
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import { FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";

function PetRegistrationsView() {
  const currentYear = new Date().getFullYear();
  const [filterStatus, setFilterStatus] = useState("Alla");
  const [filterYear, setFilterYear] = useState(currentYear);

  const columns = [
    { field: 'name', headerName: 'Namn', flex: 1, minWidth: 120 },
    { field: 'status', headerName: 'Status', flex: 1, minWidth: 100 },
    { field: 'year', headerName: 'År', flex: 1, minWidth: 80 },
    { field: 'generateIdentificationNumber', headerName: 'RegNr', flex: 1, minWidth: 80 },

  ];

  const transformData = (petRegistrations) => petRegistrations.map(registration => ({
    id: registration.registration.id,
    name: registration.pet.name,
    status: registration.registration.approvedAt ? "Godkänd" : "Ej godkänd",
    year: new Date(registration.registration.createdAt).getFullYear(),
    generateIdentificationNumber: registration.registration.generateIdentificationNumber ? "Med RegNr" : "Utan RegNr",
  }));

  return (
    <GenericListView
      title="Registreringar"
      apiCall={() => DjurliAPI.getPetRegistrations(ApplicationState.getToken())}
      columns={columns}
      searchFields={['name', 'year', 'status']} 
      detailPath="/petRegistration"
      transformData={transformData}
      returnPath={"/petRegistrations"}
    />
  );
}

export default PetRegistrationsView;