import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import {
  Box,
  Card,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import GenericAutocomplete from "./GenericAutocomplete";

function TestMatingView() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);
  const [father, setFather] = useState(null);
  const [mother, setMother] = useState(null);
  const [inbreedingCoefficient, setInbreedingCoefficient] = useState(null);
  const [calculating, setCalculating] = useState(false);
  const [familyTreeMother, setFamilyTreeMother] = useState([]);
  const [familyTreeFather, setFamilyTreeFather] = useState([]);
  const [fathers, setFathers] = useState([]);
  const [mothers, setMothers] = useState([]);

  useEffect(() => {
    const loadPets = async () => {
      try {
        setLoading(true);
        let mothers = await DjurliAPI.getPossibleMothers(ApplicationState.getToken());
        let fathers = await DjurliAPI.getPossibleFathers(ApplicationState.getToken());
        setFathers(fathers);
        setMothers(mothers);
        setLoading(false);
      } catch (error) {
        navigate("/error", { state: { errorMessage: error.message } });
      }
    };
    loadPets();
  }, [navigate]);

  useEffect(() => {
    const loadFamilyTree = async (pet, setTree) => {
      if (pet) {
        try {
          let familyTree = await DjurliAPI.getFamilyTreeForPet(
            pet.id,
            ApplicationState.getToken()
          );
          setTree(familyTree);
        } catch (error) {
          navigate("/error", { state: { errorMessage: error.message } });
        }
      }
    };

    loadFamilyTree(mother, setFamilyTreeMother);
    loadFamilyTree(father, setFamilyTreeFather);
  }, [mother, father, navigate]);

  const calculateInbreedingCoefficient = async (mother, father) => {
    if (mother && father) {
      try {
        setCalculating(true);
        let coefficient = await DjurliAPI.calculateInbreedingCoefficient(
          mother.id,
          father.id,
          ApplicationState.getToken()
        );
        setInbreedingCoefficient(coefficient);
      } catch (error) {
        navigate("/error", { state: { errorMessage: error.message } });
      } finally {
        setCalculating(false);
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box p={2} sx={{width: "90%", maxWidth: 900}}>
      <Card elevation={3} sx={{ bgcolor: "#f9f4f9" }}>
        <Box
          sx={{
            p: isMobile ? 2 : 3,
            bgcolor: "#f0e6f0",
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{
              color: "#4A0E4E",
              fontWeight: "bold",
            }}
          >
            Provparning
          </Typography>
        </Box>

        <Box sx={{ p: isMobile ? 2 : 3 }}>
          <Grid container spacing={3} justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
              <GenericAutocomplete
                id="mothers"
                options={mothers}
                value={mother}
                onChange={(event, newValue) => {setMother(newValue); calculateInbreedingCoefficient(newValue, father);}}
                getOptionLabel={(option) => option.name}
                label="Mor"
                primaryOptionProperty="name"
                secondaryOptionProperty="color"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GenericAutocomplete
                id="fathers"
                options={fathers}
                value={father}
                onChange={(event, newValue) => {setFather(newValue); calculateInbreedingCoefficient(mother, newValue);}}
                getOptionLabel={(option) => option.name}
                label="Far"
                primaryOptionProperty="name"
                secondaryOptionProperty="color"
              />
            </Grid>
          </Grid>

          {calculating ? (
            <Box mt={3}>
              <LoadingSpinner />
            </Box>
          ) : (
            inbreedingCoefficient && (
              <Box mt={3} p={2} sx={{ bgcolor: "white", borderRadius: "8px" }}>
                <Typography variant="h6" sx={{ color: "#4A0E4E", mb: 1 }}>
                  Inavelskoefficient: {inbreedingCoefficient.coefficient}%
                </Typography>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: inbreedingCoefficient.summary,
                  }}
                />
              </Box>
            )
          )}

          {familyTreeMother.html && (
            <Box mt={3} p={2} sx={{ bgcolor: "white", borderRadius: "8px" }}>
              <Typography variant="h6" sx={{ color: "#4A0E4E", mb: 2 }}>
                Släktträd - Mor
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: familyTreeMother.html }} />
            </Box>
          )}

          {familyTreeFather.html && (
            <Box mt={3} p={2} sx={{ bgcolor: "white", borderRadius: "8px" }}>
              <Typography variant="h6" sx={{ color: "#4A0E4E", mb: 2 }}>
                Släktträd - Far
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: familyTreeFather.html }} />
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default TestMatingView;