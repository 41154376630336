import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  useMediaQuery, 
  useTheme,
  Button,
} from '@mui/material';
import UserAvatar from './UserAvatar';
import ApplicationState from '../ApplicationState';
import GenericTable from './GenericTable';
import DjurliAPI from '../DjurliAPI';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import LogoutIcon from '@mui/icons-material/Logout';

const MyPageView = () => {
  const user = ApplicationState.getUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [rats, setRats] = useState([]);
  const [rabbits, setRabbits] = useState([]);
  const [litters, setLitters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userRats = await DjurliAPI.getRatsForUser(user.id, ApplicationState.getToken());
        const userRabbits = await DjurliAPI.getRabbitsForUser(user.id, ApplicationState.getToken());
        const userLitters = await DjurliAPI.getLittersForUser(user.id, ApplicationState.getToken());
        setRats(userRats);
        setRabbits(userRabbits);
        setLitters(userLitters);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [user.id]);

  const handleLogout = () => {
    ApplicationState.logout();
    window.location.href = "/";
  };

  const renderValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return (
        <Typography 
          variant="body1" 
          sx={{ 
            fontStyle: 'italic', 
            color: theme.palette.text.secondary,
            fontSize: '1rem'
          }}
        >
          Uppgift saknas
        </Typography>
      );
    }
    return (
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
        {value}
      </Typography>
    );
  };

  const ratColumns = [
    { id: 'name', label: 'Namn', },
    { id: 'breeder', label: 'Uppfödare', flex: 1, minWidth: 80 },
    { id: 'identificationNumber', label: 'RegNr', flex: 1, minWidth: 80 },
    { id: 'appearance', label: 'Utseende', flex: 1, minWidth: 120 },
    { id: 'dateOfBirth', label: 'Födelsedatum', render: (row) => new Date(row.dateOfBirth).toLocaleDateString() },
    { id: 'sex', label: 'Kön', render: (row) => row.sex === 'M' ? 'Hane' : 'Hona' },
  ];

  const rabbitColumns = [
    { id: 'name', label: 'Namn' },
    { id: 'sex', label: 'Kön', render: (row) => row.sex === 'M' ? 'Hane' : 'Hona' },
    { id: 'dateOfBirth', label: 'Födelsedatum', render: (row) => new Date(row.dateOfBirth).toLocaleDateString() },
  ];

  const litterColumns = [
    { id: 'birthDate', label: 'Födelsedatum', render: (row) => new Date(row.birthDate).toLocaleDateString() },
    { id: 'father', label: 'Far', render: (row) => row.father?.name || 'Okänd' },
    { id: 'mother', label: 'Mor', render: (row) => row.mother?.name || 'Okänd' },
  ];

  const handleRatRowClick = (rat) => {
    navigate(`/rat/${rat.id}`);
  };

  const handleRabbitRowClick = (rat) => {
    navigate(`/rabbit/${rat.id}`);
  };

  const handleLitterRowClick = (litter) => {
    navigate(`/litter/${litter.id}`);
  };

  const groupedData = [
    {
      title: "Användarinformation",
      data: [
        { label: "Förnamn", value: user.firstName },
        { label: "Efternamn", value: user.lastName },
        { label: "E-post", value: user.email },
        { label: "Ort", value: user.city },
        { label: "Roll", value: user.admin ? "Admin" : "Användare" },        
      ]
    },
  ];

  if (loading) {
    return (
        <LoadingSpinner />
    );
  }

  return (
    <Box p={2} sx={{ width: "90%" }}>
      <Card elevation={3} sx={{ bgcolor: '#f9f4f9' }}>
        <Box 
          sx={{ 
            p: isMobile ? 2 : 3, 
            bgcolor: '#f0e6f0',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
          }}
        >
          <Box 
            display="flex" 
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between" 
            alignItems={isMobile ? 'flex-start' : 'center'}
          >
            <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
              <UserAvatar size="large" sx={{ mr: 2 }} />
              <Typography 
                variant="h5" 
                component="h1" 
                sx={{ 
                  color: '#4A0E4E', 
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {`${user.firstName} ${user.lastName}`}
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={<LogoutIcon />}
              onClick={handleLogout}
              sx={{
                bgcolor: 'D6409B',
                '&:hover': {
                  opacity: '0.8',
                },
                width: isMobile ? '100%' : 'auto',
              }}
            >
              Logga ut
            </Button>
          </Box>
        </Box>

        <Box sx={{ p: isMobile ? 2 : 3, bgcolor: '#f0e6f0' }}>
          {groupedData.map((group, groupIndex) => (
            <Box key={groupIndex} sx={{ mb: 3, bgcolor: 'white', p: 2, borderRadius: '8px' }}>
              <Typography variant="h6" component="h2" sx={{ color: '#4A0E4E', mb: 2 }}>
                {group.title}
              </Typography>
              <Box sx={{ ml: 2 }}>
                {group.data.map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ color: '#666', mb: 0.5 }}>
                      {item.label}
                    </Typography>
                    {renderValue(item.value)}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
          
          {rats.length > 0 && (
            <Box sx={{ mb: 3, bgcolor: 'white', p: 2, borderRadius: '8px' }}>
              <Typography variant="h6" component="h2" sx={{ color: '#4A0E4E', mb: 2 }}>
                Mina råttor
              </Typography>
              <GenericTable
                data={rats}
                columns={ratColumns}
                onRowClick={handleRatRowClick}
                emptyMessage="Du har inga registrerade råttor."
              />
            </Box>
          )}
          
          {rabbits.length > 0 && (
            <Box sx={{ mb: 3, bgcolor: 'white', p: 2, borderRadius: '8px' }}>
              <Typography variant="h6" component="h2" sx={{ color: '#4A0E4E', mb: 2 }}>
                Mina kaniner
              </Typography>
              <GenericTable
                data={rabbits}
                columns={rabbitColumns}
                onRowClick={handleRabbitRowClick}
                emptyMessage="Du har inga registrerade kaniner."
              />
            </Box>
          )}
          {litters.length > 0 && (
            <Box sx={{ mb: 3, bgcolor: 'white', p: 2, borderRadius: '8px' }}>
              <Typography variant="h6" component="h2" sx={{ color: '#4A0E4E', mb: 2 }}>
                Mina kullar
              </Typography>
              <GenericTable
                data={litters}
                columns={litterColumns}
                onRowClick={handleLitterRowClick}
                emptyMessage="Du har inga registrerade kullar."
              />
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default MyPageView;