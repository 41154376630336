import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Box, Typography, Container, Paper } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage = location.state?.errorMessage || "Ett oväntat fel inträffade.";

  const handleSendError = () => {
    // Implement logic to send error to support
    console.log("Sending error:", errorMessage);
  };

  return (
    <Container maxWidth={false} sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "calc(100vh - 64px)",
          my: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 2,
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <ErrorOutlineIcon sx={{ fontSize: 24, color: "#d32f2f", mr: 2 }} />
            <Typography variant="h6" sx={{ color: "#d32f2f", fontWeight: "bold" }}>
              Något gick fel!
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Vi ber om ursäkt för besväret. Vänligen försök igen. Om felet kvarstår, skicka in felmeddelandet nedan tillsammans med en beksrivning av vad du gjorde till
            {' '}
            <a href={`mailto:support@skuttli.com?subject=Felrapport&body=${encodeURIComponent(errorMessage)}`}>
              support@skuttli.com
            </a>
            {' '}
            så hjälper vi dig.
          </Typography>
          {/* <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Button
              variant="contained"
              onClick={() => navigate("/")}
            >
              FÖRSÖK IGEN
            </Button>
            <Button
              variant="outlined"
              onClick={handleSendError}
            >
              SKICKA TILL SUPPORT
            </Button>
          </Box> */}
          {/* <Typography variant="body1" sx={{ mb: 2 }}>
            Maila felmeddelandet nedan tillsammans med en beskrivning av vad du gjorde till {' '}
            <a href={`mailto:support@skuttli.com?subject=Felrapport&body=${encodeURIComponent(errorMessage)}`}>
              support@skuttli.com
            </a>
          </Typography> */}
          <Box sx={{ paddingRight: 3, backgroundColor: "white" }}>
            <Typography
              variant="body2"
              sx={{ 
                wordBreak: "break-word", 
                width: "100%",
                backgroundColor: "#f5f5f5",
                p: 2,
                borderRadius: 1,
              }}
            >
              {errorMessage}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ErrorPage;