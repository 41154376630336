export default class LitterBabyDto  {
  constructor(id, litterId, name, sex, color, furType, earType, markings = null, comment = null, ownerUserId = null, distinctiveFeatures) {
    this.id = id;
    this.litterId = litterId;
    this.name = name;
    this.sex = sex;
    this.color = color;
    this.markings = markings;
    this.furType = furType;
    this.earType = earType;
    this.comment = comment;
    this.ownerUserId = ownerUserId;
    this.distinctiveFeatures = distinctiveFeatures;
  }
}