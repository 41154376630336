export default class RatDto {
    constructor(id, name, color, fatherId, motherId, prefix, markings, furType, earType, comment, identificationNumber, dateOfBirth, dateOfDeath, causeOfDeath, sex, breederId, breederText, ownerUserId, ownerText, petType, distinctiveFeatures) {
      this.id = id;
      this.name = name;
      this.color = color;
      this.fatherId = fatherId;
      this.motherId = motherId;
      this.prefix = prefix;
      this.markings = markings;
      this.furType = furType;
      this.earType = earType;
      this.comment = comment;
      this.identificationNumber = identificationNumber;
      this.dateOfBirth = dateOfBirth;
      this.dateOfDeath = dateOfDeath;
      this.causeOfDeath = causeOfDeath;  
      this.sex = sex;
      this.breederId = breederId;
      this.breederText = breederText;
      this.ownerUserId = ownerUserId;
      this.ownerText = ownerText;
      this.petType = petType;
      this.distinctiveFeatures = distinctiveFeatures;
    }
  }  