import DjurliAPI from "./DjurliAPI";
import { jwtDecode } from 'jwt-decode';


export const StateKey = "State";

export default class ApplicationState {
  static storeState(state) {
    sessionStorage.setItem(StateKey, JSON.stringify(state));
  }

  static hasState() {
    let state = sessionStorage.getItem(StateKey);
    return state !== undefined && state !== null;
  }

  static clearState() {
    sessionStorage.setItem(StateKey, JSON.stringify({}));
  }

  static getState() {
    let value = sessionStorage.getItem(StateKey);
    if (value === undefined || value === null) {
      return this.createAndSaveNewState();
    }
    try {
      var object = JSON.parse(value);
      return object;
    } catch (e) {
      console.error("Failed to parse stored state: " + e);
      // Om parsning misslyckas, skapa och spara ett nytt state
      return this.createAndSaveNewState();
    }
  }
  
  static createAndSaveNewState() {
    const newState = {};
    sessionStorage.setItem(StateKey, JSON.stringify(newState));
    return newState;
  }

  static initilizeState() {
    let state = ApplicationState.getState();
    ApplicationState.storeState(state);
  }

  static async loginUser(email, loginMethod, loginToken) {
    let userResult = await DjurliAPI.loginUser(email, loginMethod, loginToken);
    if (userResult === "Login failed!") {
      return "Login failed!";
    }
    let state = ApplicationState.getState();
    state.user = userResult.user;
    state.loginMethod = loginMethod;
    state.token = userResult.token;
    ApplicationState.storeState(state);
    let organization = await DjurliAPI.getOrganization(ApplicationState.getToken());
    let petType = organization.petType;
    if (petType === "Rat") {
      this.setPetMenuTitle("Råttor");
    } else if (petType === "Rabbit") {
      this.setPetMenuTitle("Kaniner");
    } else {
      this.setPetMenuTitle(petType);
    }
    this.setPetRoute("/" + organization.petType + "s");
  }

  static async loadDataForUser() {
    let state = ApplicationState.getState();
    if (!state.user) {
      throw Error("Failed to load user from stored state");
    }
    // state.projects = await ApplicationState.dataService.getProjectsForUser(
    //   state.user.userId
    // );
    ApplicationState.storeState(state);
  }

  static getUser() {
    return this.getState().user;
  }

  static getLoginMethod() {
    return this.getState().loginMethod;
  }

  static getToken(){
    return this.getState().token;
  }

  static setToken(token){
    let state = this.getState();
    state.token = token;
    this.storeState(state);
  }

  static logout() {
    this.clearSessionStorageFromMicrosoftLoginInfo();
    ApplicationState.clearState();
  }

  static clearSessionStorageFromMicrosoftLoginInfo() {
    //This is done because logout function logs user out from the account, not just only Dynamic Time
    Object.keys(sessionStorage).forEach(key => {
      if(key.startsWith("msal.") || key.startsWith('{"authority":"https://login.microsoftonline.com')){
        sessionStorage.removeItem(key);
      }
    });
  }

  static viewMenuItem() {
    return true;
  }

  static isAdmin() {
    let state = ApplicationState.getState();

    let token = this.getToken();
    let decodedToken = jwtDecode(token);

    if (state.user && state.user.admin && decodedToken.role && decodedToken.role.includes("admin")) {
      return true;
    }
    return false;
  }
    
  static getUser() {
    return this.getState().user;
  }

  static setUser(user) {
    let state = this.getState();
    state.user = user;
    this.storeState(state);
  }

  static getRatToEdit() {
    return this.getState().ratToEdit;
  }

  static setRatToEdit(ratToEdit) {
    let state = this.getState();
    state.ratToEdit = ratToEdit;
    this.storeState(state);
  }

  static getUserToEdit() {
    return this.getState().userToEdit;
  }

  static setUserToEdit(userToEdit) {
    let state = this.getState();
    state.userToEdit = userToEdit;
    this.storeState(state);
  }

  static getBreederToEdit() {
    return this.getState().breederToEdit;
  }

  static setBreederToEdit(breederToEdit) {
    let state = this.getState();
    state.breederToEdit = breederToEdit;
    this.storeState(state);
  }

  static getLitterToEdit() {
    return this.getState().litterToEdit;
  }

  static setLitterToEdit(litterToEdit) {
    let state = this.getState();
    state.litterToEdit = litterToEdit;
    this.storeState(state);
  }

  static getMembershipToEdit() {
    return this.getState().membershipToEdit;
  }

  static setMembershipToEdit(membershipToEdit) {
    let state = this.getState();
    state.membershipToEdit = membershipToEdit;
    this.storeState(state);
  }

  static getUserToAddMembershipFor() {
    return this.getState().userToAddMembershipFor;
  }

  static setUserToAddMembershipFor(userToAddMembershipFor) {
    let state = this.getState();
    state.userToAddMembershipFor = userToAddMembershipFor;
    this.storeState(state);
  }
  
  static getUserProfilePictureUrl() {
    return this.getState().userProfilePictureUrl;
  }

  static setUserProfilePictureUrl(userProfilePictureUrl) {
    let state = this.getState();
    state.userProfilePictureUrl = userProfilePictureUrl;
    this.storeState(state);
  }

  static getLitterBabyToEdit() {
    return this.getState().litterBabyToEdit;
  }

  static setLitterBabyToEdit(litterBabyToEdit) {
    let state = this.getState();
    state.litterBabyToEdit = litterBabyToEdit;
    this.storeState(state);
  }

  static getLitterToAddBabyFor() {
    return this.getState().litterToAddBabyFor;
  }

  static setLitterToAddBabyFor(litterToAddBabyFor) {
    let state = this.getState();
    state.litterToAddBabyFor = litterToAddBabyFor;
    this.storeState(state);
  }

  static getPetMenuTitle() {
    return this.getState().petMenuTitle;
  }

  static setPetMenuTitle(petMenuTitle) {
    let state = this.getState();
    state.petMenuTitle = petMenuTitle;
    this.storeState(state);
  }

  static getPetRoute() {
    return this.getState().petRoute;
  }

  static setPetRoute(petRoute) {
    let state = this.getState();
    state.petRoute = petRoute;
    this.storeState(state);
  }

  static getRabbitToEdit() {
    return this.getState().rabbitToEdit;
  }

  static setRabbitToEdit(rabbitToEdit) {
    let state = this.getState();
    state.rabbitToEdit = rabbitToEdit;
    this.storeState(state);
  }
}
