import GenericLogView from './GenericLogView';
import DjurliAPI from '../DjurliAPI';

const fieldMappings = {
  city: "Ort",
  createdAt: "Skapad",
  ownerText: "Ägare",
  ownerUserId: "ÄgareId",
  prefix: "Prefix",
};

const BreederLogView = () => (
  <GenericLogView
    getLogsFunction={DjurliAPI.getLogsForBreeder.bind(DjurliAPI)}
    getObjectFunction={DjurliAPI.getBreeder.bind(DjurliAPI)}
    fieldMappings={fieldMappings}
    objectName="uppfödaren"
    objectNameField="prefix"
  />
);

export default BreederLogView;