import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const GenericCheckbox = ({ 
  checked, 
  onChange, 
  label, 
  style = { marginLeft: "0px", marginBottom: "0px" } 
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
        />
      }
      label={label}
      style={style}
    />
  );
};

export default GenericCheckbox;