import React from 'react';
import { Avatar, Typography, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ApplicationState from "../ApplicationState";

const UserAvatar = ({ size = 'large', showName = false }) => {
  const navigate = useNavigate();
  const user = ApplicationState.getUser();
  const profilePictureUrl = ApplicationState.getUserProfilePictureUrl();

  const handleClick = () => {
    navigate('/mypage');
  };

  // Definiera storlekar baserat på 'size' prop
  const avatarSize = size === 'large' ? 85 : 40;
  const fontSize = size === 'large' ? 40 : 20;
  const typographyVariant = size === 'large' ? 'body1' : 'body2';

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        justifyContent: "center",
      }}
    >
      <IconButton onClick={handleClick} size={size}>
        {profilePictureUrl && profilePictureUrl.length > 0 ? (
          <Avatar
            src={profilePictureUrl}
            alt={user.firstName}
            sx={{
              width: avatarSize,
              height: avatarSize,
            }}
          />
        ) : (
          <Avatar
            sx={{
              width: avatarSize,
              height: avatarSize,
              fontSize: fontSize,
              backgroundColor: '#EB71A9',
              color: '#eee',
            }}
          >
            {user.firstName.charAt(0).toUpperCase()}
            {user.lastName.charAt(0).toUpperCase()}
          </Avatar>
        )}
      </IconButton>
      {showName === true && (
        <Typography
          variant={typographyVariant}
          style={{
            color: "#fff",
            padding: "8px",
            paddingTop: "12px",
            paddingBottom: "0px",
            wordBreak: "break-word",
            textAlign: "center",
          }}
        >
          {user.firstName + " " + user.lastName}
        </Typography>
      )}
    </Grid>
  );
};

export default UserAvatar;