import logo from "./logo_with_text_djurli.png";
import "./App.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DjurliAPI from "./DjurliAPI";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import RatDto from "./Dtos/RatDto";
import RatsView from "./Components/RatsView";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import TopBar from "./Components/TopBar";

function App() {
  // const [rats, setRats] = useState([]);

  //Kör vid uppstart
  useEffect(() => {
    // const load1 = async () => {
    //   let le = await DjurliAPI.getRats();
    //   setRats(le);
    // };
    // load1();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#D6409B",
      },
      secondary: {
        main: "#fff",
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      {/* <img
                          src={logo}
                          style={{
                            height: "50px",
                            width: "155px",
                            margin: "8px 8px 8px 4px",
                            top: "57px",
                            left: "58px",
                            alignContent: "",
                          }}
                        /> */}
        {/* <img src={logo} className="App-logo" alt="logo" />
        
        <Typography variant="h1">Djurli</Typography>
        <RatsView></RatsView> */}
        <TopBar></TopBar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
