import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

function GenericDataGrid({ items, columns, onRowClick }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  if (!isDesktop && !isTablet) return null;

  return (
    <DataGrid
      rows={items}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      onRowClick={(params) => onRowClick(params.id)}
      sx={{
        backgroundColor: '#fff',
        borderRadius: 2,
        '& .MuiDataGrid-root': {
          width: '100%',
          border: 'none',
        },
        '& .MuiDataGrid-main': {
          width: '100%',
          borderRadius: "8px",
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#4A0E4E',
          color: 'white',
          borderRadius: 0,
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: '#4A0E4E',
          padding: '0 16px',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          color: 'white',
          fontWeight: 'bold',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },
        '& .MuiDataGrid-cell': {
          padding: '0 16px',
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiDataGrid-columnSeparator': {
          color: 'white',
        },
        '& .MuiDataGrid-virtualScroller': {
          width: '100% !important',
        },
        '& .MuiDataGrid-sortIcon': {
          color: 'white !important',
        },
        '& .MuiDataGrid-columnHeader:hover .MuiDataGrid-sortIcon': {
          color: 'white !important',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-sortIcon': {
          color: 'white !important',
        },
        '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
          color: 'white !important',
        },
        '& .MuiDataGrid-filterIcon': {
          color: 'white !important',
        },
        '& .MuiDataGrid-columnHeader:hover .MuiDataGrid-filterIcon': {
          color: 'white !important',
        },
        '& .MuiDataGrid-menuIcon': {
          color: 'white',
        },
        '& .MuiDataGrid-menuIconButton': {
          color: 'white',
        },
        '& .MuiDataGrid-iconButtonContainer': {
          color: 'white',
        },
        '& .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon': {
          visibility: 'visible',
          width: 'auto',
        },
        '& .MuiDataGrid-columnHeadersInner': {
          borderColor: 'white !important',
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
          borderColor: 'white',
        },
      }}
    />
  );
}

export default GenericDataGrid;