import React from 'react';
import { useNavigate } from 'react-router-dom';

// Funktionell komponent för att hantera omdirigeringen
function ErrorRedirect() {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/error');
  }, [navigate]);
  return null;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div>
            <h1>Något gick fel.</h1>
            <p>{this.state.error && this.state.error.toString()}</p>
          </div>
          <ErrorRedirect />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;