import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import DjurliAPI from "../DjurliAPI";
// import DjurliAPI from "./DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Grid,
  Typography,
  Card,
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import RatDto from "../Dtos/RatDto";
import ApplicationState from "../ApplicationState";
import { CheckBox } from "@mui/icons-material";
import PetRegistrationDto from "../Dtos/PetRegistrationDto";
import GenericSelect from "./GenericSelect";
import CustomTextField from "./CustomTextField";
import GenericAutocomplete from "./GenericAutocomplete";
import GenericCheckbox from "./GenericCheckbox";
import BreederHelper from "./BreederHelper";

function RatCrudView(props) {
  let history = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [title, setTitle] = useState("Ny Råtta");

  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [prefix, setPrefix] = useState("");
  const [markings, setMarkings] = useState("");
  const [furType, setFurType] = useState("");
  const [earType, setEarType] = useState("");
  const [distinctiveFeatures, setDistinctiveFeatures] = useState("");
  const [comment, setComment] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [causeOfDeath, setCauseOfDeath] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [dateOfDeath, setDateOfDeath] = useState(undefined);
  const [sex, setSex] = useState("");
  const [generateIdentificationNumber, setGenerateIdentificationNumber] =
    useState(false);

  const [fathers, setFathers] = useState([]);
  const [father, setFather] = useState(null);
  const [mothers, setMothers] = useState([]);
  const [mother, setMother] = useState(null);
  const [breeders, setBreeders] = useState([]);
  const [breeder, setBreeder] = useState(null);
  const [breederText, setBreederText] = useState("");
  const [breederIsRegistered, setBreederIsRegistered] = useState(false);
  const [owners, setOwners] = useState([]);
  const [owner, setOwner] = useState(null);
  const [ownerText, setOwnerText] = useState("");
  const [ownerHasAccount, setOwnerHasAccount] = useState(false);

  const [nameError, setNameError] = useState("");
  const [colorError, setColorError] = useState("");
  const [prefixError, setPrefixError] = useState("");
  const [markingsError, setMarkingsError] = useState("");
  const [furTypeError, setFurTypeError] = useState("");
  const [earTypeError, setEarTypeError] = useState("");
  const [commentError, setCommentError] = useState("");
  const [identificationNumberError, setIdentificationNumberError] =
    useState("");
  const [causeOfDeathError, setCauseOfDeathError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [dateOfDeathError, setDateOfDeathError] = useState("");
  const [sexError, setSexError] = useState("");
  const [breederError, setBreederError] = useState("");
  const [ownerError, setOwnerError] = useState("");

  const earTypeOptions = [
    { value: 'Toppöra', label: 'Toppöra' },
    { value: 'Dumboöra', label: 'Dumboöra' },
    { value: 'Dumbobärare', label: 'Dumbobärare' },
  ];

  const sexOptions = [
    { value: "M", label: "Hane" },
    { value: "F", label: "Hona" }
  ];

  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      try {
        let rats = await DjurliAPI.getRats(ApplicationState.getToken());
        rats = rats.sort((a, b) => a.name.localeCompare(b.name));
        let fathers = rats.filter((rat) => rat.sex === "M");
        let mothers = rats.filter((rat) => rat.sex === "F");
        setFathers(fathers);
        setMothers(mothers);

        let users = await DjurliAPI.getUsers(ApplicationState.getToken());
        users = users.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setOwners(users);

        let breeders = await DjurliAPI.getBreeders(ApplicationState.getToken());
        setBreeders(breeders);

        let ratToEdit = ApplicationState.getRatToEdit();
        //rat with specific id

        if (ratToEdit) {
          setTitle("Redigera Råtta");
          setName(ratToEdit.name);
          setColor(ratToEdit.color);
          setPrefix(ratToEdit.prefix);
          setMarkings(ratToEdit.markings);
          setFurType(ratToEdit.furType);
          setEarType(ratToEdit.earType);
          setDistinctiveFeatures(ratToEdit.distinctiveFeatures);
          setComment(ratToEdit.comment);
          setIdentificationNumber(ratToEdit.identificationNumber);
          setCauseOfDeath(ratToEdit.causeOfDeath);
          if (ratToEdit.dateOfBirth) {
            setDateOfBirth(
              new Date(ratToEdit.dateOfBirth).toLocaleDateString("sv-SE")
            );
          }
          if (ratToEdit.dateOfDeath) {
            setDateOfDeath(
              new Date(ratToEdit.dateOfDeath).toLocaleDateString("sv-SE")
            );
          }
          setBreederText(ratToEdit.breederText);
          setOwnerText(ratToEdit.ownerText);

          if (ratToEdit.motherId) {
            let mother = await DjurliAPI.getRat(
              ratToEdit.motherId,
              ApplicationState.getToken()
            );
            setMother(mother);
          }
          if (ratToEdit.fatherId) {
            let father = await DjurliAPI.getRat(
              ratToEdit.fatherId,
              ApplicationState.getToken()
            );
            setFather(father);
          }

          if (ratToEdit.breederId) {
            let b = await DjurliAPI.getBreeder(
              ratToEdit.breederId,
              ApplicationState.getToken()
            );
            setBreeder(b);
            setBreederIsRegistered(true);
          }
          if (ratToEdit.ownerUserId) {
            let o = await DjurliAPI.getUser(
              ratToEdit.ownerUserId,
              ApplicationState.getToken()
            );
            setOwner(o);
            setOwnerHasAccount(true);
          }

          setSex(ratToEdit.sex);
        }
      } catch (error) {
        history("/error", { state: { errorMessage: error.message } });
      }
    };
    load1();
  }, []);

  const validateControls = () => {
    let valid = true;
    if (!validateName()) valid = false;
    if (!validateColor()) valid = false;
    if (!validatePrefix()) valid = false;
    if (!validateMarkings()) valid = false;
    if (!validateFurType()) valid = false;
    if (!validateEarType()) valid = false;
    if (!validateComment()) valid = false;
    if (!validateIdentificationNumber()) valid = false;
    if (!validateCauseOfDeath()) valid = false;
    if (!validateDateOfBirth()) valid = false;
    if (!validateDateOfDeath()) valid = false;
    if (!validateSex()) valid = false;
    if (!validateBreeder()) valid = false;
    if (!validateOwner()) valid = false;
    return valid;
  };

  function validateName() {
    if (name.length < 3) {
      setNameError("Namnet måste vara minst 3 tecken långt.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  }

  function validateColor() {
    if (color.length < 1) {
      setColorError("Färg måste anges.");
      return false;
    } else {
      setColorError("");
      return true;
    }
  }

  function validatePrefix() {
    setPrefixError("");
    return true;
  }

  function validateMarkings() {
    setMarkingsError("");
    return true;
  }

  function validateFurType() {
    if (furType.length < 1) {
      setFurTypeError("Pälstyp måste anges.");
      return false;
    } else {
      setFurTypeError("");
      return true;
    }
  }

  function validateEarType() {
    if (earType === "" || !earType) {
      setEarTypeError("Örontyp måste väljas.");
      return false;
    } else {
      setEarTypeError("");
      return true;
    }
  }

  function validateComment() {
    setCommentError("");
    return true;
  }

  function validateIdentificationNumber() {
    setIdentificationNumberError("");
    return true;
  }

  function validateCauseOfDeath() {
    setCauseOfDeathError("");
    return true;
  }

  function validateDateOfBirth() {
    setDateOfBirthError("");
    return true;
  }

  function validateDateOfDeath() {
    setDateOfDeathError("");
    return true;
  }

  function validateSex() {
    if (sex === "?") {
      setSexError("Kön måste väljas.");
      return false;
    } else {
      setSexError("");
      return true;
    }
  }

  function validateBreeder() {
    setBreederError("");
    return true;
  }

  function validateOwner() {
    setOwnerError("");
    return true;
  }

  return (
    <Box p={2} sx={{maxWidth: 600}}>
    <Card elevation={3} style={{ backgroundColor: "#f9f4f9" }}>
    <Box p={2}>
          <Typography
            variant={"h5"}
            component="h1"
            sx={{
              color: "#4A0E4E",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            p: isMobile ? 2 : 3,
            bgcolor: "white",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        >
          <Typography variant="h6" sx={{ mb: 1 }}>Grundläggande information</Typography>
        <CustomTextField
            id="nameTextField"
            label="Namn"
            value={name}
            error={Boolean(nameError)}
            helperText={nameError}
            onChange={(event) => setName(event.target.value)}
            onBlur={validateName}
          />

        <GenericSelect
          id="sex-select"
          label="Kön"
          value={sex}
          onChange={(event) => {
            setSex(event.target.value);
          }}
          onBlur={validateSex}
          options={sexOptions}
          error={sexError}
          helperText={sexError}
          maxWidth={600}
        />
        
        <CustomTextField
            id="dateOfBirthTextField"
            type="date"
            label="Födelsedatum"
            value={dateOfBirth}
            error={Boolean(dateOfBirthError)}
            helperText={dateOfBirthError}
            onChange={(event) => setDateOfBirth(event.target.value)}
            onBlur={validateDateOfBirth}
          />

<CustomTextField
            id="prefixTextField"
            label="Prefix"
            value={prefix}
            error={Boolean(prefixError)}
            helperText={prefixError}
            onChange={(event) => setPrefix(event.target.value)}
            onBlur={validatePrefix}
          />
          <CustomTextField
          id="identificationNumberTextField"
          label="RegNr"
          value={identificationNumber}
          error={Boolean(identificationNumberError)}
          helperText={identificationNumberError}
          onChange={(event) => setIdentificationNumber(event.target.value)}
          onBlur={validateIdentificationNumber}
        />

<Typography variant="h6" sx={{ mb: 1, mt: 3 }}>Utseende</Typography>
        <CustomTextField
            id="colorTextField"
            label="Färg"
            value={color}
            error={Boolean(colorError)}
            helperText={colorError}
            onChange={(event) => setColor(event.target.value)}
            onBlur={validateColor}
          />

          <CustomTextField
            id="markingsTextField"
            label="Teckning"
            value={markings}
            error={Boolean(markingsError)}
            helperText={markingsError}
            onChange={(event) => setMarkings(event.target.value)}
            onBlur={validateMarkings}
          />

          <CustomTextField
            id="furTypeTextField"
            label="Päls"
            value={furType}
            error={Boolean(furTypeError)}
            helperText={furTypeError}
            onChange={(event) => setFurType(event.target.value)}
            onBlur={validateFurType}
          />

        <GenericSelect
          id="ear-type-select"
          label="Örontyp"
          value={earType}
          onChange={(event) => {
            setEarType(event.target.value);
          }}
          onBlur={validateEarType}
          options={earTypeOptions}
          error={earTypeError}
          helperText={earTypeError}
        />

        <CustomTextField
          id="distinctiveFeaturesTextField"
          label="Speciella kännetecken"
          value={distinctiveFeatures}
          onChange={(event) => setDistinctiveFeatures(event.target.value)}
        />
        
        <Typography variant="h6" sx={{ mb: 1, mt: 3 }}>Föräldrar</Typography>

        <GenericAutocomplete
            id="mothers"
            options={mothers}
            value={mother}
            onChange={(event, newValue) => setMother(newValue)}
            getOptionLabel={(option) => option.name}
            label="Mor"
            primaryOptionProperty="name"
            secondaryOptionProperty="color"
          />

          <GenericAutocomplete
            id="fathers"
            options={fathers}
            value={father}
            onChange={(event, newValue) => setFather(newValue)}
            getOptionLabel={(option) => option.name}
            label="Far"
            primaryOptionProperty="name"
            secondaryOptionProperty="color"
          />

<Typography variant="h6" sx={{ mb: 1, mt: 3 }}>Uppfödare</Typography>
        <GenericCheckbox
          checked={breederIsRegistered}
          onChange={setBreederIsRegistered}
          label="Uppfödare är registrerad"
        />

        {breederIsRegistered && (      
          <GenericAutocomplete
            id="breeders"
            options={breeders}
            value={breeder}
            onChange={(event, newValue) => {
              setBreeder(newValue);
              setBreederText(undefined);
            }}
            getOptionLabel={(option) => BreederHelper.getTitleString(option)}
            label="Uppfödare"
            primaryOptionProperty={(option) => BreederHelper.getTitleString(option)}
            secondaryOptionProperty="city"
          />
        )}
        {!breederIsRegistered && (
          <Grid container>
            <CustomTextField
              id="breederTextField"
              label="Uppfödare"
              value={breederText}
              error={Boolean(breederError)}
              helperText={breederError}
              onChange={(event) => {
                setBreederText(event.target.value);
                setBreeder(undefined);
              }}
              onBlur={validateBreeder}
            />
          </Grid>
        )}

<Typography variant="h6" sx={{ mb: 1, mt: 3 }}>Ägare</Typography>
        <GenericCheckbox
          checked={ownerHasAccount}
          onChange={setOwnerHasAccount}
          label="Ägare har konto"
        />

        {ownerHasAccount && (
           <GenericAutocomplete
           id="owners"
           options={owners}
           value={owner}
           onChange={(event, newValue) => {
             setOwner(newValue);
             setOwnerText(undefined);
           }}
           getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
           label="Ägare"
           primaryOptionProperty={(option) => `${option.firstName} ${option.lastName}`}
           secondaryOptionProperty="email"
         />
        )}
        {!ownerHasAccount && (
          <Grid container>
            <CustomTextField
            id="ownerTextField"
            label="Ägare"
            value={ownerText}
            error={Boolean(ownerError)}
            helperText={ownerError}
            onChange={(event) => {
              setOwnerText(event.target.value);
              setOwner(undefined);
            }}
            onBlur={validateOwner}
          />
          </Grid>
        )}

<Typography variant="h6" sx={{ mb: 1, mt: 3 }}>Övrig information</Typography>
        <CustomTextField
          id="dateOfDeathTextField"
          type="date"
          label="Dödsdatum"
          value={dateOfDeath}
          error={Boolean(dateOfDeathError)}
          helperText={dateOfDeathError}
          onChange={(event) => setDateOfDeath(event.target.value)}
          onBlur={validateDateOfDeath}
        />
        <CustomTextField
          id="causeOfDeathTextField"
          label="Dödsorsak"
          value={causeOfDeath}
          error={Boolean(causeOfDeathError)}
          helperText={causeOfDeathError}
          onChange={(event) => setCauseOfDeath(event.target.value)}
          onBlur={validateCauseOfDeath}
        />
        <CustomTextField
          id="commentTextField"
          label="Kommentar"
          value={comment}
          error={Boolean(commentError)}
          helperText={commentError}
          onChange={(event) => setComment(event.target.value)}
          onBlur={validateComment}
          multiline
        />
        {dateOfBirth && !ApplicationState.getRatToEdit() && (
          <GenericCheckbox
            checked={generateIdentificationNumber}
            onChange={setGenerateIdentificationNumber}
            label="Registrera med RegNr"
          />
        )}
      </Box>
      <Divider style={{ borderColor: "#d8d8d8" }} />
      <Box p={2}>
        <Button
          variant="contained"
          onClick={async () => {
            try {
              if (!validateControls()) {
                return;
              }
              let fatherIdToSave = undefined;
              let motherIdToSave = undefined;

              if (father) {
                fatherIdToSave = father.id;
              }
              if (mother) {
                motherIdToSave = mother.id;
              }

              let breederIdToSave = undefined;
              let breederTextToSave = breederText;
              if (!breederIsRegistered) {
                setBreeder(undefined);
              }
              if (breeder) {
                breederIdToSave = breeder.id;
                breederTextToSave = undefined;
              }

              let ownerIdToSave = undefined;
              let ownerTextToSave = ownerText;
              if (!ownerHasAccount) {
                setOwner(undefined);
              }
              if (owner) {
                ownerIdToSave = owner.id;
                ownerTextToSave = undefined;
              }

              let dateOfBirthToSave = undefined;
              if (dateOfBirth) {
                dateOfBirthToSave = new Date(dateOfBirth).toLocaleDateString(
                  "sv-SE"
                );
              }

              let dateOfDeathToSave = undefined;
              if (dateOfDeath) {
                dateOfDeathToSave = new Date(dateOfDeath).toLocaleDateString(
                  "sv-SE"
                );
              }

              let dto = new RatDto(
                DjurliAPI.generateGuid(),
                name,
                color,
                fatherIdToSave,
                motherIdToSave,
                prefix,
                markings,
                furType,
                earType,
                comment,
                identificationNumber,
                dateOfBirthToSave,
                dateOfDeathToSave,
                causeOfDeath,
                sex,
                breederIdToSave,
                breederTextToSave,
                ownerIdToSave,
                ownerTextToSave,
                "Rat",
                distinctiveFeatures
              );
              let ratToEdit = ApplicationState.getRatToEdit();
              if (ratToEdit) {
                dto.id = ratToEdit.id;
                dto.createdAt = ratToEdit.createdAt;
                dto.createdBy = ratToEdit.createdBy;
                dto.petType = ratToEdit.petType;
                await DjurliAPI.updateRat(dto, ApplicationState.getToken());
              } else {
                await DjurliAPI.createRat(dto, ApplicationState.getToken());
                if (dto.dateOfBirth) {
                  let petRegistrationDto = new PetRegistrationDto(
                    DjurliAPI.generateGuid(),
                    dto.id,
                    undefined,
                    undefined,
                    generateIdentificationNumber,
                    "Rat"
                  );
                  await DjurliAPI.createPetRegistration(
                    petRegistrationDto,
                    ApplicationState.getToken()
                  );
                }
              }

              history("/rat/" + dto.id);
            } catch (error) {
              history("/error", { state: { errorMessage: error.message } });
            }
          }}
        >
          Spara
        </Button>
      </Box>
    </Card>
    </Box>
  );
}

export default RatCrudView;
