import React, { useEffect, useState } from "react";
import DjurliAPI from "../DjurliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ApplicationState from "../ApplicationState";
import CustomTextField from "./CustomTextField";
import GenericAutocomplete from "./GenericAutocomplete";
import LitterBabyDto from "../Dtos/LitterBabyDto";
import GenericSelect from "./GenericSelect";

function LitterBabyCrudView(props) {
  let history = useNavigate();

  const [title, setTitle] = useState("Ny unge");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("");
  const [color, setColor] = useState("");
  const [markings, setMarkings] = useState("");
  const [furType, setFurType] = useState("");
  const [earType, setEarType] = useState("");
  const [distinctiveFeatures, setDistinctiveFeatures] = useState("");
  const [comment, setComment] = useState("");
  const [owners, setOwners] = useState([]);
  const [owner, setOwner] = useState(null);

  const [nameError, setNameError] = useState("");
  const [sexError, setSexError] = useState("");
  const [colorError, setColorError] = useState("");
  const [furTypeError, setFurTypeError] = useState("");
  const [earTypeError, setEarTypeError] = useState("");
  const [ownerError, setOwnerError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sexOptions = [
    { value: "M", label: "Hane" },
    { value: "F", label: "Hona" }
  ];

  const earTypeOptions = [
    { value: 'Toppöra', label: 'Toppöra' },
    { value: 'Dumboöra', label: 'Dumboöra' },
    { value: 'Dumbobärare', label: 'Dumbobärare' },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        let babyToEdit = ApplicationState.getLitterBabyToEdit();
        if (babyToEdit) {
          setTitle("Redigera unge");
          setName(babyToEdit.name);
          setSex(babyToEdit.sex);
          setColor(babyToEdit.color);
          setMarkings(babyToEdit.markings || "");
          setFurType(babyToEdit.furType);
          setEarType(babyToEdit.earType);
          setDistinctiveFeatures(babyToEdit.distinctiveFeatures || "");
          setComment(babyToEdit.comment || "");
        }

        let users = await DjurliAPI.getUsers(ApplicationState.getToken());
        users = users.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setOwners(users);

        if (babyToEdit && babyToEdit.ownerUserId) {
          let o = await DjurliAPI.getUser(
            babyToEdit.ownerUserId,
            ApplicationState.getToken()
          );
          setOwner(o);
        }
      } catch (error) {
        history("/error", { state: { errorMessage: error.message } });
      }
    };
    loadData();
  }, []);

  const validateControls = () => {
    let isValid = true;
  
    isValid = validateName() && isValid;
    isValid = validateSex() && isValid;
    isValid = validateColor() && isValid;
    isValid = validateFurType() && isValid;
    isValid = validateEarType() && isValid;
    isValid = validateOwner() && isValid;
  
    return isValid;
  };

  function validateName() {
    if (name.length < 1) {
      setNameError("Namn måste anges.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  }

  function validateSex() {
    if (sex.length !== 1 || (sex !== 'M' && sex !== 'F')) {
      setSexError("Kön måste vara 'M' eller 'F'.");
      return false;
    } else {
      setSexError("");
      return true;
    }
  }

  function validateColor() {
    if (color.length < 1) {
      setColorError("Färg måste anges.");
      return false;
    } else {
      setColorError("");
      return true;
    }
  }

  function validateFurType() {
    if (furType.length < 1) {
      setFurTypeError("Pälstyp måste anges.");
      return false;
    } else {
      setFurTypeError("");
      return true;
    }
  }

  function validateEarType() {
    if (earType.length < 1) {
      setEarTypeError("Örontyp måste anges.");
      return false;
    } else {
      setEarTypeError("");
      return true;
    }
  }

  function validateOwner() {
    setOwnerError("");
    return true;
  }

  return (
    <Box p={2} sx={{maxWidth: 600}}>
      <Card elevation={3} style={{ backgroundColor: "#f9f4f9" }}>
        <Box p={2}>
          <Typography
            variant={"h5"}
            component="h1"
            sx={{
              color: "#4A0E4E",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            p: isMobile ? 2 : 3,
            bgcolor: "white",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        >
          <CustomTextField
            id="nameTextField"
            label="Namn"
            value={name}
            error={Boolean(nameError)}
            helperText={nameError}
            onChange={(event) => setName(event.target.value)}
            onBlur={validateName}
          />
          
          <GenericSelect
            id="sex-select"
            label="Kön"
            value={sex}
            onChange={(event) => {
              setSex(event.target.value);
            }}
            onBlur={validateSex}
            options={sexOptions}
            error={sexError}
            helperText={sexError}
            maxWidth={600}
          />

          <CustomTextField
            id="colorTextField"
            label="Färg"
            value={color}
            error={Boolean(colorError)}
            helperText={colorError}
            onChange={(event) => setColor(event.target.value)}
            onBlur={validateColor}
          />

          <CustomTextField
            id="markingsTextField"
            label="Teckning"
            value={markings}
            onChange={(event) => setMarkings(event.target.value)}
          />

          <CustomTextField
            id="furTypeTextField"
            label="Pälstyp"
            value={furType}
            error={Boolean(furTypeError)}
            helperText={furTypeError}
            onChange={(event) => setFurType(event.target.value)}
            onBlur={validateFurType}
          />

          <GenericSelect
            id="ear-type-select"
            label="Örontyp"
            value={earType}
            onChange={(event) => {
              setEarType(event.target.value);
            }}
            onBlur={validateEarType}
            options={earTypeOptions}
            error={earTypeError}
            helperText={earTypeError}
          />

          <CustomTextField
            id="distinctiveFeaturesTextField"
            label="Speciella kännetecken"
            value={distinctiveFeatures}
            onChange={(event) => setDistinctiveFeatures(event.target.value)}
          />

          <GenericAutocomplete
            id="owners"
            options={owners}
            value={owner}
            onChange={(event, newValue) => {
              setOwner(newValue);
            }}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            label="Ägare"
            primaryOptionProperty={(option) => `${option.firstName} ${option.lastName}`}
            secondaryOptionProperty="email"
          />

          <CustomTextField
            id="commentTextField"
            label="Kommentar"
            multiline={true}
            maxWidth={600}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </Box>
        
        <Box p={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={async () => {
              try {
                if (!validateControls()) {
                  return;
                }

                let dto = new LitterBabyDto(
                  DjurliAPI.generateGuid(),
                  undefined,
                  name,
                  sex,
                  color,
                  furType,
                  earType,
                  markings,
                  comment,
                  owner ? owner.id : undefined,
                  distinctiveFeatures
                );

                let babyToEdit = ApplicationState.getLitterBabyToEdit();
                if (babyToEdit) {
                  dto.id = babyToEdit.id;
                  dto.createdAt = babyToEdit.createdAt;
                  dto.createdBy = babyToEdit.createdBy;
                  dto.litterId = babyToEdit.litterId;
                  await DjurliAPI.updateLitterBaby(dto, ApplicationState.getToken());
                } else {
                  dto.litterId = ApplicationState.getLitterToAddBabyFor().id;
                  await DjurliAPI.createLitterBaby(dto, ApplicationState.getToken());
                }
                history("/litter/" + dto.litterId);
              } catch (error) {
                history("/error", { state: { errorMessage: error.message } });
              }
            }}
          >
            Spara
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default LitterBabyCrudView;