import React from "react";
import GenericListView from './GenericListView';
import DjurliAPI from "../DjurliAPI";
import ApplicationState from "../ApplicationState";
import BreederHelper from "./BreederHelper";
import { MaximizeTwoTone } from "@mui/icons-material";

function LittersView() {
  const columns = [
    { field: 'prefix', headerName: 'Uppfödare', flex: 1, minWidth: 120 },
    { field: 'birthDate', headerName: 'Födelsedatum', flex: 1, minWidth: 100, },
    { field: 'father', headerName: 'Far', flex: 1, minWidth: 120 },
    { field: 'mother', headerName: 'Mor', flex: 1, minWidth: 120 },
  ];

  const transformData = (litters) => litters.map(litter => ({
    id: litter.id,
    prefix: BreederHelper.getTitleString(litter.breeder),
    birthDate: litter.birthDate ? new Date(litter.birthDate).toLocaleDateString("sv-SE") : undefined,
    father: litter.father?.name,
    mother: litter.mother?.name
  }));

  return (
    <GenericListView
      title="Kullar"
      newItemButtonText="Ny kull"
      newItemPath="/litterCrud"
      apiCall={() => DjurliAPI.getLitters(ApplicationState.getToken())}
      columns={columns}
      searchFields={['prefix', 'father', 'mother']}
      detailPath="/litter"
      transformData={transformData}
      onNewItemClick={() => ApplicationState.setLitterToEdit(undefined)}
      returnPath={"/litters"}
    />
  );
}

export default LittersView;