import GenericLogView from './GenericLogView';
import DjurliAPI from '../DjurliAPI';

const fieldMappings = {
  comment: "Kommentar",
  createdAt: "Skapad",
  name: "Namn",
  color: "Färg",
  furType: "Päls",
  breederId: "UppfödarId",
  breederText: "Uppfödare",
  dateOfBirth: "Födelsedatum",
  dateOfDeath: "Dödsdatum",
  causeOfDeath: "Dödsorsak",
  earType: "Öron",
  fatherId: "FarId",
  fatherText: "Far",
  identificationNumber: "RegNr",
  markings: "Teckning",
  motherId: "MorId",
  motherText: "Mor",
  ownerText: "Ägare",
  ownerUserId: "ÄgarId",
  sex: "Kön",
};

const RatLogView = () => (
  <GenericLogView
    getLogsFunction={DjurliAPI.getLogsForRat.bind(DjurliAPI)}
    getObjectFunction={DjurliAPI.getRat.bind(DjurliAPI)}
    fieldMappings={fieldMappings}
    objectName="råttan"
    objectNameField="name"
  />
);

export default RatLogView;